import {
  TasksWithStyleType,
  ActiveHighLevelOutcomeTaskIds,
  ListsType,
  ListType,
  TaskWithStyleType
} from "./Board.component";
import {
  BoardStateType,
  FileContentType,
  ImportDataV1,
  VERSION_0_0,
  LatestImportData,
  ImportDataV0,
  ImportDataV2,
  ImportDataV3,
  VERSION_0_1,
  VERSION_0_2,
  VERSION_0_3
} from "./BoardState";
import { CSSProperties } from "react";

const addStyleToTasks = (
  oldState: Pick<BoardStateType, "lists" | "tasks"> & {
    highLevelListID: string;
  }
): TasksWithStyleType => {
  const { lists, tasks, highLevelListID } = oldState;
  const taskTypesWithStyle: TasksWithStyleType = {};

  const highLevelTasksID: ActiveHighLevelOutcomeTaskIds =
    lists[highLevelListID].taskIds;
  // For long term outcome tasks
  highLevelTasksID.forEach((item) => {
    if (!tasks[item]) {
      // During import of data it's possible for Lists to refer to Tasks that don't exist if Lists was setState'd before Tasks.
      throw Error(
        `List refers to taskId ("${item}") that is not present in tasks`
      );
    }
    // updating long term outcome color within long term outcome tasks
    taskTypesWithStyle[item] = {
      ...tasks[item]
    };
  });
  // For non long term outcome tasks
  Object.keys(tasks).forEach((key) => {
    const { highLevelTaskIDs = [], id } = tasks[key];
    if (highLevelTasksID.indexOf(id) !== -1) return; //if this task is long term outcome, skip
    let colorTarget: string[] | undefined = undefined;
    //only assign a color if it is still a long term outcome

    highLevelTaskIDs.forEach((id) => {
      if (highLevelTasksID.indexOf(id) !== -1) {
        const colours = tasks[id].cardColour;
        if (colours) {
          if (!colorTarget) colorTarget = [];
          colorTarget = [...colorTarget, ...colours];
        }
      }
    });

    taskTypesWithStyle[key] = {
      ...tasks[key],
      cardColour: colorTarget
    };
  });
  return taskTypesWithStyle;
};

type Props = {
  listID: string | undefined;
  highLevelListID: string | undefined;
  index: number;
  list: ListType | undefined;
};

const getListDetailsFromTask = (taskID: string, lists: ListsType): Props => {
  let listID: string | undefined = undefined;
  let resultIndex: number = 0;
  let highLevelListID: string = "";
  let _list: ListType | undefined;

  Object.values(lists).forEach((list: ListType) => {
    const tasksInList = list.taskIds;
    const index = tasksInList.indexOf(taskID);
    if (index !== -1) {
      listID = list.id;
      resultIndex = index;
      _list = list;
    }
    if (list.isHighLevel) {
      highLevelListID = list.id;
    }
  });

  return {
    listID,
    index: resultIndex,
    list: _list,
    highLevelListID
  };
};

const upgradev0Tov1 = (v0Data: ImportDataV0): ImportDataV1 => {
  return { ...v0Data, indicators: {} };
};

const upgradev1Tov2 = (v1Data: ImportDataV1): ImportDataV2 => {
  const rawData = { ...v1Data };
  const { tasks } = rawData;
  // we might need to look into versioned types?
  Object.values(tasks).forEach((task: TaskWithStyleType) => {
    // @ts-ignore
    const highLevelTaskID = task.highLevelTaskID;
    if (highLevelTaskID) {
      const idAsString = String(highLevelTaskID);
      task.highLevelTaskIDs = [idAsString];
      // @ts-ignore
      task.highLevelTaskID = null;
    }
    task.cardColour = task.cardColour ? [String(task.cardColour)] : undefined;
  });
  return rawData;
};

const upgradev2Tov3 = (v2Data: ImportDataV2): ImportDataV3 => {
  const rawData = { ...v2Data };
  const { lists } = rawData;
  const shouldShowIndicators = ["list-3", "list-4", "list-5"];

  for (let [key, list] of Object.entries(lists)) {
    const { id } = list;
    const isStandard = shouldShowIndicators.indexOf(id) === -1;
    if (isStandard) {
      list.type = "standard";
    } else {
      list.type = "outcome";
    }
  }

  return rawData;
};

const assessFormat = (json: FileContentType): LatestImportData => {
  const { version, data } = json;
  if (!version || version === "")
    throw Error(`Invalid file, it should have a valid version.`);
  if (!data) {
    throw Error(`Invalid file, it should have valid data.`);
  }
  let result = data;

  const versionHistory = [VERSION_0_0, VERSION_0_1, VERSION_0_2, VERSION_0_3];
  const parsers = [upgradev0Tov1, upgradev1Tov2, upgradev2Tov3];

  const indexFound = versionHistory.indexOf(version);
  for (let index = indexFound; index < parsers.length; index++) {
    const parse = parsers[index];
    result = parse(result);
  }

  return result;
};

type FileReaderProps = {
  result: string | ArrayBuffer | null;
  onImportData: Function;
};

const handleFileRead = ({ result, onImportData }: FileReaderProps) => {
  const resultContent = result;
  let resJson: FileContentType = {
    readme: "",
    version: ""
  };
  if (typeof resultContent !== "string") return;
  try {
    // we need to remove spaces(\s) at the  end of a line (\n) and replace them with just a linebreak
    const removeWhiteSpaceAndEnters = resultContent.replace(/\s\\n/g, "\\n");
    resJson = JSON.parse(removeWhiteSpaceAndEnters);
    const format = assessFormat(resJson);
    console.log({ format });
    onImportData(format);
  } catch (e) {
    alert(`Invalid file. (code "${e.toString()}" from data ${resultContent})`);
  }
};

function loadJSON(filePath: string, callback: Function) {
  var xobj = new XMLHttpRequest();
  xobj.overrideMimeType("application/json");
  xobj.open("GET", filePath, true);
  xobj.onreadystatechange = function () {
    if (xobj.readyState === 4 && xobj.status === 200) {
      // .open will NOT return a value but simply returns undefined in async mode so use a callback
      callback(xobj.responseText);
    }
  };
  xobj.send(null);
}

const BoardHasData = (lists: ListsType) =>
  Object.keys(lists).some((key) => {
    return lists[key].taskIds.length !== 0;
  });

const tooltipStyle: CSSProperties = {
  background: "hsla(0, 0%, 0%, 0.75)",
  color: "white",
  border: "none",
  borderRadius: "4px",
  maxWidth: "100vw",
  whiteSpace: "initial",
  padding: "1em 1em",
  zIndex: 2000
};

const createLinkageStyle = (task: TaskWithStyleType, list: ListType): {} => {
  const widthForColours = 20;
  let borderStyle: any = {
    paddingLeft: `${widthForColours}px`,
    border: 0
  };
  if (task.cardColour) {
    const colourWidth = widthForColours / task.cardColour.length;

    let gradient = "to right";
    if (list.isHighLevel) {
      borderStyle.border = `4px solid ${task.cardColour[0]}`;
    } else {
      const sortedColours = [...task.cardColour];
      // sort so colours always append in same order
      sortedColours.sort();

      sortedColours.forEach((colour, index) => {
        gradient += `,${colour} ${index * colourWidth}px,${colour} ${
          (index + 1) * colourWidth
        }px `;
      });
      const lastStop = (sortedColours.length - 1) * colourWidth;
      borderStyle.backgroundImage = `linear-gradient( ${gradient} ,#FFFFFF ${lastStop}px)`;
      borderStyle.paddingLeft = `${lastStop + colourWidth + 10}px`;
      borderStyle.borderBottomLeftRadius = 0;
      borderStyle.borderTopLeftRadius = 0;
    }
  }

  return borderStyle;
};

export {
  createLinkageStyle,
  addStyleToTasks,
  getListDetailsFromTask,
  assessFormat,
  loadJSON,
  handleFileRead,
  BoardHasData,
  tooltipStyle
};
