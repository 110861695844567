import React, { Fragment } from "react";
import FieldSelect from "../Field/FieldSelect";
import FieldButton from "../Field/FieldButton";

import {
  ListCategoryType,
  ListType,
  LIST_CATEGORIES
} from "../Board/Board.component";
import InputTextPlain from "../Field/FieldInputTextPlain";
import InputTextArea from "../Field/FieldTextarea";
import { getGloss, GLOSS } from "../Field/getGloss";

type Props = {
  list: ListType;
  onChange: (id: string, updatedProps: Partial<ListType>) => void;
  onRemove: (id: string) => void;
};

const ListEdit = ({ list, onChange, onRemove }: Props) => {
  const onTitleChange = (event: any) => {
    onChange(list.id, { title: event.target.value });
  };

  const onTipChange = (event: any) => {
    onChange(list.id, { tip: event.target.value });
  };
  const onTypeChange = (event: any) => {
    onChange(list.id, { type: event.target.value });
  };

  return (
    <div>
      <InputTextPlain
        label="Title"
        name="title"
        value={list.title}
        onChange={onTitleChange}
        placeholder={getGloss(GLOSS.LIST_UPDATE_TITLE_PLACEHOLDER)}
        maxLength={40}
      />
      <InputTextArea
        label="Tip"
        value={list.tip}
        onChange={onTipChange}
        placeholder={getGloss(GLOSS.LIST_UPDATE_TIP_PLACEHOLDER)}
        noresize
      />

      {!list.isHighLevel && (
        <Fragment>
          <FieldSelect
            label="List Type"
            value={list.type}
            onChange={onTypeChange}
          >
            {LIST_CATEGORIES.map((val: ListCategoryType) => (
              <option value={val} key={val}>
                {val}
              </option>
            ))}
          </FieldSelect>

          <hr />

          <FieldButton
            onClick={() => {
              const result = window.confirm(
                `Confirm deleting "${list.title}"?`
              );
              if (result) {
                onRemove(list.id);
              }
            }}
            icon="delete"
          >
            Delete List
          </FieldButton>
        </Fragment>
      )}
    </div>
  );
};

export default ListEdit;
