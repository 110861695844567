import React from "react";
import "./IndicatorList.scss";

type Props = {
  children: React.ReactNode;
};

const IndicatorList = ({ children }: Props) => {
  return <div className="indicator-list">{children}</div>;
};

export default IndicatorList;
