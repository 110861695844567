import React from "react";
import AppContext from "../AppState";
import "./ToolBarButton.scss";

const ToolBarButtonHelp = () => {
  return (
    <AppContext.Consumer>
      {(value) => {
        const openModal = () => {
          if (!value.modals.setModalOpen) return;
          value.modals.setModalOpen({
            type: "Help"
          });
        };
        return (
          <button
            className="toolbar__button"
            onClick={openModal}
            data-test-button-help
          >
            Help
          </button>
        );
      }}
    </AppContext.Consumer>
  );
};

export default ToolBarButtonHelp;
