import { BoardStateType } from "./BoardState";
import { TasksType, ListsType, TaskWithStyleType } from "./Board.component";

const createCardReducer = (
  oldState: Pick<BoardStateType, "lists" | "tasks">,
  card: TaskWithStyleType,
  listId: string
): CreateCardReducerType => {
  const { lists, tasks } = oldState;
  const { id, label, description, highLevelTaskIDs, indicators } = card;

  const newTasks: TasksType = {
    ...tasks,
    [id]: { id, label, description, highLevelTaskIDs, indicators }
  };
  //TODO: replace list-1 with the target list
  const newLists: ListsType = {
    ...lists,
    [listId]: { ...lists[listId], taskIds: [...lists[listId].taskIds, id] }
  };

  return { tasks: newTasks, lists: newLists, newTaskId: id };
};

export type CreateCardReducerType = {
  lists: ListsType;
  tasks: TasksType;
  newTaskId: string;
};

export default createCardReducer;
