import React from "react";
import AppContext from "../AppState";
import "./ToolBarButton.scss";

type Props = {
  title: string;
};

const ToolBarButtonEdit = ({ title }: Props) => {
  return (
    <AppContext.Consumer>
      {value => {
        const openModal = () => {
          if (!value.modals.setModalOpen) return;
          value.modals.setModalOpen({
            type: "EditBoardTitle"
          });
        };
        return (
          <button
            className="toolbar__button toolbar__button--title"
            onClick={openModal}
            data-test-button-edit-filters
          >
            {title}
          </button>
        );
      }}
    </AppContext.Consumer>
  );
};

export default ToolBarButtonEdit;
