import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import filter from "lodash/filter";

import FieldButton from "../Field/FieldButton";
import BoardContext, { BoardContextType } from "../Board/BoardState";
import { CreateListPropsType } from "../Board/Board.component";
import ListEdit from "./ListEdit.form.component";

type Props = {
  preExpanded: string[];
};
const UpdateListsView = ({ preExpanded }: Props) => {
  const [RAAUpdateID, setRAAUpdateID] = useState(0);
  return (
    <BoardContext.Consumer>
      {(boardValue: BoardContextType) => {
        const {
          lists,
          listsOrder,
          onCreateList,
          onUpdateList,
          onRemoveList,
          onDragListEnd
        } = boardValue;

        const onAddListClick = () => {
          const createListProps: CreateListPropsType = {
            type: "standard",
            title: "",
            tip: ""
          };
          onCreateList(createListProps);

          setRAAUpdateID(RAAUpdateID + 1);
        };

        const getNoTitleLists = (): string[] | undefined => {
          const noTitle = filter(
            lists,
            ({ title }) => !title || title.length === 0
          );
          const noTitleIDS = noTitle.map((list) => list.id);

          return noTitleIDS.length > 0 ? noTitleIDS : undefined;
        };

        const openByDefault = getNoTitleLists() || preExpanded;

        return (
          <DragDropContext onDragEnd={onDragListEnd}>
            <div className="filtermodalcard">
              <h2 className="modal__heading">Update lists</h2>

              <Accordion
                allowZeroExpanded
                preExpanded={openByDefault}
                // this is a dirty hack because preExpanded is only used on mount
                // by giving it a new key only when created, we force it to re-creatte and mount
                // therefor expanding the correct items
                key={RAAUpdateID}
              >
                <Droppable droppableId="droppable-1" direction="vertical">
                  {(provided) => {
                    return (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {listsOrder.map((listId, index) => {
                          const list = lists[listId];

                          return (
                            <Draggable
                              draggableId={listId}
                              index={index}
                              key={listId}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <AccordionItem uuid={list.id} key={list.id}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          {list.title}
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <ListEdit
                                          list={list}
                                          onChange={onUpdateList}
                                          onRemove={onRemoveList}
                                        />
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Accordion>
              <div
                className="field__button--right"
                style={{ marginTop: "1rem" }}
              >
                <FieldButton level="confirm" onClick={onAddListClick}>
                  Add List
                </FieldButton>
              </div>
            </div>
          </DragDropContext>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default UpdateListsView;
