import { Styles } from "react-modal";
import "./BoardModal.scss";

export const modalStyles: Styles = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    display: "flex"
  },
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",

    margin: "0 auto",
    maxWidth: "600px",
    width: "100%",
    // height: "100%",
    maxHeight: "95vh",
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "scroll"
  }
};
