import { ListType } from "../Board/Board.component";

const updateListReducer = (
  oldState: ListType,
  newList: Partial<ListType>
): ListType => {
  return { ...oldState, ...newList };
};

export default updateListReducer;
