import React, { useContext } from "react";
import BoardContext from "../Board/BoardState";

import "./RouteView.scss";
import "../Board/Printable.scss";
import SummaryList from "../Summary/SummaryList";
import SummaryHorizontalList from "../Summary/SummaryHorizontalList";
import "../Summary/Summary.scss";
import { convertToHTML } from "../utils";

const Summary = () => {
  const {
    tasks,
    lists,
    listsOrder,
    evaluationDescription,
    evaluationTitle
  } = useContext(BoardContext);
  const reversedList = [...listsOrder].reverse();
  const assumptionListID = reversedList[0];
  const assumptionList = lists[assumptionListID];
  reversedList.shift();
  const assumptionsTasks = assumptionList.taskIds.map((id) => tasks[id]);
  const highLevelLists = Object.values(lists).filter((list) => {
    return list.isHighLevel;
  });
  const highLevelTaskOrder: string[] = [];
  highLevelLists.forEach((list) => {
    highLevelTaskOrder.push(...list.taskIds);
  });

  const dangerousHTML = convertToHTML(evaluationDescription);
  return (
    <div className="route-view summary">
      <h1 className="row printable summary__title"> {evaluationTitle}</h1>
      <div className="row summary__row">
        {reversedList.map((id) => {
          const list = lists[id];
          const summaryTasks = list.taskIds.map((id) => tasks[id]);
          //if a list is NOT a highleve list, we need to order the cards by high order ID
          if (!list.isHighLevel) {
            // sort the summaryTasks by id in  the same order as the highLevelTasskOrder variable
            summaryTasks.sort((a, b) => {
              // we sort on the first colour, as that will be the most important one

              if (
                a.highLevelTaskIDs &&
                b.highLevelTaskIDs &&
                a.highLevelTaskIDs.length > 0 &&
                b.highLevelTaskIDs.length > 0
              ) {
                const val =
                  highLevelTaskOrder.indexOf(a.highLevelTaskIDs[0]) -
                  highLevelTaskOrder.indexOf(b.highLevelTaskIDs[0]);

                return val;
              }
              if (a.highLevelTaskIDs && a.highLevelTaskIDs.length > 0) {
                return -1;
              }
              if (b.highLevelTaskIDs && b.highLevelTaskIDs.length > 0) {
                return 1;
              }

              return 0;
            });
          }
          return <SummaryList key={id} tasks={summaryTasks} list={list} />;
        })}
      </div>

      <div className="row summary__row">
        <div className="col-xs-12">
          <SummaryHorizontalList
            tasks={assumptionsTasks}
            list={assumptionList}
          />
          <div className={`col-xs-12 `}>
            <div className="summary-list">
              <div className="summary-list__title gutters">Description</div>
              <div className="summary-list__cards gutters">
                <span dangerouslySetInnerHTML={{ __html: dangerousHTML }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
