import React, { useState, useCallback, useEffect } from "react";
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import { scrollToCard } from "./BoardCard/BoardCard.component";
import {
  ListsType,
  ListsOrderType,
  TaskType,
  TasksType,
  ListTitleType,
  IndicatorsType,
  IndicatorType,
  TasksWithStyleType,
  TaskWithStyleType,
  ListType,
  CreateListPropsType
} from "./Board.component";
import dragEndReducer, { filterResult } from "./Board.DragEnd.reducer";
import dragListEndReducer from "../List/List.DragEnd.reducer";
import createCardReducer from "./Board.CreateCard.reducer";
import { DEFAULT_TASK_SUGGESTIONS } from "./TaskSuggestions";
import archiveCardReducer from "./Board.ArchiveCard.reducer";
import updateCardReducer from "./Board.UpdateCard.reducer";
import updateHighLevelLink from "./Board.UpdateHighLevelLink.reducer";
import addIndicatorReducer from "./Board.AddIndicatorToCard.reducer";
import removeIndicatorReducer from "./Board.RemoveIndicatorToCard.reducer";
import addIndicatorToListReducer from "../Indicator/Indicator.addIndicatorToList.reducer";
import removeIndicatorFromListReducer from "../Indicator/Indicator.removeIndicatorFromList.reducer";
import updateIndicatorReducer from "../Indicator/Indicator.updateIndicator.reducer";
import moveTaskToListReducer from "./Board.MoveCardToList.reducer";
import filterListsReducer, { FilterType } from "./Board.FilterLists.reducer";
import { addStyleToTasks, getListDetailsFromTask } from "./Board.utils";
import { resetApp } from "../index";
import { AppProps } from "../App";
import createListReducer from "./Board.CreateList.reducer";
import moveListReducer, { MoveListPropsType } from "./Board.MoveList.reducer";
import deleteListReducer from "./Board.DeleteList.reducer";
import updateListReducer from "../List/List.updateList.reducer";

const BoardContext = React.createContext<BoardContextType>({
  lists: {},
  unfilteredLists: {},
  listsOrder: [],
  indicators: {},
  tasks: {},
  onDragEnd: () => {},
  onDragListEnd: () => {},
  onCreateCard: () => {},
  onArchiveCard: () => {
    return undefined;
  },
  onUpdateCard: () => {},
  onAddIndicatorToCard: () => {},
  onRemoveIndicatorFromCard: () => {},
  onAddIndicatorToList: () => {},
  onRemoveIndicatorFromList: () => {},
  onUpdateIndicator: () => {},
  onMoveTaskToListReducer: () => {},
  onSetFilter: () => {},
  filter: undefined,
  maxBoardListWidthPx: window.outerWidth,
  taskSuggestions: {},
  onSetEvaluationTitle: () => {},
  onSetEvaluationDescription: () => {},
  evaluationTitle: "",
  evaluationDescription: "",
  highLevelListID: "",
  onImportData: () => {},
  onAddHighLevelLink: () => {},
  onRemoveHighLevelLink: () => {},
  isEmptyBoard: () => false,
  onCreateList: (createListProps: CreateListPropsType) => {},
  onMoveList: (moveListProps: MoveListPropsType) => {},
  onRemoveList: (id: string) => {},
  onUpdateList: () => {},
  isValidTemplateName: (id: string): boolean => {
    return false;
  }
});

const templateNames = [
  "ministry_template",
  "reading_recovery",
  "demodata",
  "covid19_distance_learning",
  "period-products-initiative-theory-of-change"
] as const;

export type BoardStateProps = {
  prepopulatedLists: ListsType;
  prepopulatedTasks: TasksType;
  prepopulatedIndicators: IndicatorsType;
  prepopulatedTitle: string;
  prepopulatedDescription: string;
  prepopulatedListsOrder: ListsOrderType;
};

export const useBoardState = ({
  prepopulatedLists,
  prepopulatedTasks,
  prepopulatedIndicators,
  prepopulatedTitle,
  prepopulatedDescription,
  prepopulatedListsOrder
}: BoardStateProps): BoardContextType => {
  const [filter, setFilter] = useState<FilterType | undefined>(undefined);
  const [lists, setLists] = useState<ListsType>(
    prepopulatedLists || DEFAULT_BOARD_LISTS
  );
  const [listsOrder, setListsOrder] = useState<ListsOrderType>(
    prepopulatedListsOrder || DEFAULT_BOARD_LISTS_ORDER
  );
  const [indicators, setIndicators] = useState<IndicatorsType>(
    prepopulatedIndicators || DEFAULT_INDICATORS
  );
  const [tasks, setTasks] = useState<TasksType>(prepopulatedTasks || {});
  const [newTaskId, setNewTaskId] = useState<string | undefined>();
  const [maxBoardListWidthPx, setMaxBoardListWidthPx] = useState<number>(
    window.outerWidth
  );
  const [taskSuggestions] = useState<TaskSuggestions>(DEFAULT_TASK_SUGGESTIONS);
  // const [outcomeGroups] = useState<OutcomeGroupsType>(DEFAULT_OUTCOME_GROUPS);
  const [evaluationTitle, setEvaluationTitle] = useState<string>(
    prepopulatedTitle || DEFAULT_TITLE
  );
  const [evaluationDescription, setEvaluationDescription] = useState<string>(
    prepopulatedDescription || ""
  );

  //filter
  useEffect(() => {
    if (newTaskId === undefined) return;
    const timer = setTimeout(
      () => {
        setNewTaskId(undefined);
      },
      1000 // must be enough time for CSS animation to complete
    );
    return () => {
      clearTimeout(timer);
      setNewTaskId(undefined);
    };
  }, [newTaskId]);

  useEffect(() => {
    setMaxBoardListWidthPx(window.outerWidth);
    setTimeout(() => {
      const boardlists = document.querySelectorAll(".boardlist");
      if (!boardlists) {
        throw Error(
          "Unable to find boardlists to determine area. This will affect list drop size (shorter lists won't be expanded to max width)"
        );
      }
      const maxWidth = Math.max(
        ...Array.from(boardlists).map(
          (boardlist) => (boardlist as HTMLElement).scrollWidth
        )
      );
      setMaxBoardListWidthPx(maxWidth);
    }, 250);
  }, [lists]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const boardStatePickLists: Pick<BoardStateType, "lists"> = {
        lists
      };

      const newLists = dragEndReducer(
        boardStatePickLists,
        filterResult(result)
      );
      if (newLists) {
        setLists(newLists);
      }
    },
    [lists]
  );

  const onDragListEnd = useCallback(
    (result: DropResult) => {
      const state: Pick<BoardStateType, "listsOrder"> = {
        listsOrder
      };

      const newListsOrder = dragListEndReducer(state, filterResult(result));
      if (newListsOrder) {
        setListsOrder(newListsOrder);
      }
    },
    [listsOrder]
  );

  const onCreateCard = useCallback(
    (newCard: TaskType, listID: string) => {
      const boardStatePickListsAndTasks: Pick<
        BoardStateType,
        "lists" | "tasks"
      > = {
        lists,
        tasks
      };
      const {
        lists: newLists,
        tasks: newTasks,
        newTaskId
      } = createCardReducer(boardStatePickListsAndTasks, newCard, listID);
      setTasks(newTasks);
      setLists(newLists);
      setNewTaskId(newTaskId);

      scrollToCard(newTaskId);
    },
    [lists, tasks]
  );

  const onArchiveCard = useCallback(
    (taskID: string) => {
      const { lists: newLists, archivedFromList } = archiveCardReducer(
        { lists },
        taskID
      );
      setLists(newLists);

      return archivedFromList;
    },
    [lists]
  );

  const onUpdateCard = useCallback(
    (taskID: string, updatedProperties: Partial<TaskType>) => {
      const updatedTask = updateCardReducer(tasks[taskID], updatedProperties);
      const updatedTaskList = { ...tasks };
      updatedTaskList[taskID] = updatedTask;
      setTasks(updatedTaskList);
    },
    [tasks]
  );

  const onAddIndicatorToCard = useCallback(
    (task: TaskType, indicatorID: string) => {
      const { indicators } = addIndicatorReducer(task, indicatorID);
      const updatedTask = { ...task, indicators };
      const updatedTaskList = { ...tasks };
      updatedTaskList[task.id] = updatedTask;
      setTasks(updatedTaskList);
    },
    [tasks]
  );

  const onRemoveIndicatorFromCard = useCallback(
    (task: TaskType, indicatorID: string) => {
      const { indicators } = removeIndicatorReducer(task, indicatorID);
      const updatedTaskList = { ...tasks };
      updatedTaskList[task.id] = { ...task, indicators };
      setTasks(updatedTaskList);
    },
    [tasks]
  );

  const onAddIndicatorToList = useCallback(
    (indicator: IndicatorType, taskID) => {
      const updated = addIndicatorToListReducer(indicators, indicator);
      setIndicators(updated);
      onAddIndicatorToCard(tasks[taskID], indicator.id);
    },
    [indicators, onAddIndicatorToCard, tasks]
  );

  const onRemoveIndicatorFromList = useCallback(
    (id: string) => {
      //batch process before setting the state!
      const updatedTaskList = Object.values(tasks)
        .map((task) => {
          const { indicators } = removeIndicatorReducer(task, id);
          return { ...task, indicators };
        })
        .reduce((taskList, updatedTask) => {
          taskList[updatedTask.id] = updatedTask;
          return taskList;
        }, {} as TasksType);
      setTasks(updatedTaskList);

      const updated = removeIndicatorFromListReducer(indicators, id);
      setIndicators(updated);
    },
    [indicators, tasks]
  );

  const onSetEvaluationTitle = useCallback((title: string) => {
    setEvaluationTitle(title);
  }, []);
  const onSetEvaluationDescription = useCallback((desc: string) => {
    setEvaluationDescription(desc);
  }, []);

  const onUpdateIndicator = useCallback(
    (id: string, updatedProperties: Partial<IndicatorType>) => {
      const updated = updateIndicatorReducer(indicators[id], updatedProperties);
      const newState = { ...indicators };
      newState[id] = updated;
      setIndicators(newState);
    },
    [indicators]
  );

  const onMoveTaskToListReducer = (taskID: string, targetListID: string) => {
    const newLists = moveTaskToListReducer(lists, tasks[taskID], targetListID);
    setLists(newLists);

    scrollToCard(taskID);
  };
  const { highLevelListID = "" } = getListDetailsFromTask("", lists);

  const taskTypesWithStyle: TasksWithStyleType = addStyleToTasks({
    lists,
    tasks,
    highLevelListID
  });

  const onSetFilter = (filter: FilterType | undefined) => setFilter(filter);

  const filteredLists = filterListsReducer(lists, tasks, filter);

  const onImportData = useCallback(
    (data: LatestImportData, template?: TemplateNameType) => {
      const {
        lists: importedLists,
        listsOrder: importedOrder,
        tasks: importedTasks,
        indicators: importedIndicators,
        evaluationTitle: importedTitle,
        evaluationDescription: importedDecription
      } = data;

      const defaultProps: AppProps = {
        template,
        defaultBoardState: {
          prepopulatedLists: importedLists,
          prepopulatedTasks: importedTasks,
          prepopulatedIndicators: importedIndicators,
          prepopulatedTitle: importedTitle,
          prepopulatedDescription: importedDecription,
          prepopulatedListsOrder: importedOrder
        }
      };
      // we reset the whole app when JSON is loaded.

      console.log("reset app ", defaultProps);
      resetApp(defaultProps);
    },
    [lists]
  );

  const onAddHighLevelLink = (taskID: string, highLevelTaskID: string) => {
    const updatedTask = updateHighLevelLink(
      tasks[taskID],
      highLevelTaskID,
      true
    );
    const updatedTaskList = { ...tasks };
    updatedTaskList[taskID] = updatedTask;
    setTasks(updatedTaskList);
  };
  const onRemoveHighLevelLink = (taskID: string, highLevelTaskID: string) => {
    const updatedTask = updateHighLevelLink(
      tasks[taskID],
      highLevelTaskID,
      false
    );
    const updatedTaskList = { ...tasks };
    updatedTaskList[taskID] = updatedTask;
    setTasks(updatedTaskList);
  };

  const onCreateList = useCallback(
    (createListProps: CreateListPropsType) => {
      const { lists: newLists, listsOrder: newListsOrder } = createListReducer(
        {
          lists,
          listsOrder
        },
        createListProps
      );
      setListsOrder(newListsOrder);
      setLists(newLists);
    },
    [lists, listsOrder]
  );

  const onUpdateList = useCallback(
    (id: string, updatedProperties: Partial<ListType>) => {
      const oldState = lists[id];

      const updated = updateListReducer(oldState, updatedProperties);
      const newState = { ...lists };
      newState[id] = updated;
      setLists(newState);
    },
    [lists]
  );

  const onMoveList = useCallback(
    (moveListProps: MoveListPropsType) => {
      const { listsOrder: newListsOrder } = moveListReducer(
        {
          listsOrder
        },
        moveListProps
      );
      setListsOrder(newListsOrder);
    },
    [listsOrder]
  );

  const onRemoveList = useCallback(
    (listID: string) => {
      const { lists: newLists, listsOrder: newListOrder } = deleteListReducer(
        { lists, listsOrder },
        listID
      );
      setListsOrder(newListOrder);
      setLists(newLists);
    },
    [lists, listsOrder]
  );

  const isValidTemplateName = (id: TemplateNameType) => {
    return templateNames.indexOf(id) !== -1;
  };

  const isEmptyBoard = useCallback(() => {
    return Object.keys(tasks).length === 0;
  }, [tasks]);

  return {
    filter,
    lists: filteredLists,
    unfilteredLists: lists,
    listsOrder,
    tasks: taskTypesWithStyle,
    indicators,
    onDragEnd,
    onDragListEnd,
    onCreateCard,
    onUpdateCard,
    onArchiveCard,
    onAddIndicatorToCard,
    onRemoveIndicatorFromCard,
    onAddIndicatorToList,
    onRemoveIndicatorFromList,
    onUpdateIndicator,
    onMoveTaskToListReducer,
    newTaskId,
    maxBoardListWidthPx,
    taskSuggestions,
    evaluationTitle,
    onSetEvaluationTitle,
    evaluationDescription,
    onSetEvaluationDescription,
    onSetFilter,
    highLevelListID,
    onImportData,
    onAddHighLevelLink,
    onRemoveHighLevelLink,
    onCreateList,
    onMoveList,
    onRemoveList,
    onUpdateList,
    isValidTemplateName,
    isEmptyBoard
  };
};

export type BoardBaseType = {
  lists: ListsType;
  unfilteredLists: ListsType;
  listsOrder: ListsOrderType;
  indicators: IndicatorsType;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onDragListEnd: (result: DropResult, provided: ResponderProvided) => void;
  onCreateCard: (newTask: TaskType, listID: string) => void;
  onArchiveCard: (taskID: string) => string | undefined;
  onUpdateCard: (
    taskID: string,
    updatedProperties: Partial<TaskWithStyleType>
  ) => void;
  onAddIndicatorToCard: (task: TaskType, indicatorID: string) => void;
  onAddIndicatorToList: (indicator: IndicatorType, taskID: string) => void;
  onRemoveIndicatorFromList: (id: string) => void;
  onMoveTaskToListReducer: (tasskID: string, targetListID: string) => void;
  onUpdateIndicator: (
    id: string,
    updatedProperties: Partial<IndicatorType>
  ) => void;
  onRemoveIndicatorFromCard: (task: TaskType, indicatorID: string) => void;
  newTaskId?: string;
  maxBoardListWidthPx: number;
  taskSuggestions: TaskSuggestions;
  onSetEvaluationTitle: (title: string) => void;
  onSetEvaluationDescription: (description: string) => void;
  evaluationTitle: string;
  evaluationDescription: string;
  onSetFilter: (filter: FilterType | undefined) => void;
  filter: FilterType | undefined;
  onAddHighLevelLink: (taskID: string, highLevelTaskID: string) => void;
  onRemoveHighLevelLink: (taskID: string, highLevelTaskID: string) => void;
  onCreateList: (createListProps: CreateListPropsType) => void;
  onMoveList: (moveListProps: MoveListPropsType) => void;
  onUpdateList: (id: string, updatedProperties: Partial<ListType>) => void;
  onRemoveList: (id: string) => void;
  isValidTemplateName: (id: TemplateNameType) => boolean;
  isEmptyBoard: () => boolean;
};

export type TemplateNameType = typeof templateNames[number];

export type BoardStateType = BoardBaseType & {
  tasks: TasksWithStyleType;
};

//file format version 1:
export type FileContentType = {
  readme: string;
  version: string;
  data?: ImportDataV1;
};

export type ImportDataV1 = ImportDataV0 & Pick<BoardStateType, "indicators">;
export type ImportDataV2 = ImportDataV0 & Pick<BoardStateType, "indicators">;
export type ImportDataV3 = ImportDataV2;

export const VERSION_0_0 = "0.0";
export const VERSION_0_1 = "0.1";
export const VERSION_0_2 = "0.2";
export const VERSION_0_3 = "0.3";

//version 0 for reference and unit testing:
export type ImportDataV0 = Pick<
  BoardStateType,
  "lists" | "listsOrder" | "tasks" | "evaluationTitle" | "evaluationDescription"
>;

export type FileContentTypeV0 = Pick<FileContentType, "version" | "readme"> & {
  data?: ImportDataV0;
};

export type ImportData = ImportDataV0 | ImportDataV1;
export type LatestImportData = ImportDataV1;

export type BoardContextType = BoardBaseType & {
  tasks: TasksWithStyleType;
  highLevelListID: string;
  onImportData: (data: LatestImportData, template: TemplateNameType) => void;
};

export const DEFAULT_BOARD_LISTS: ListsType = {
  "list-0": {
    id: "list-0",
    title: "Assumptions" as ListTitleType,
    taskIds: [],
    tip: "What is an assumption?\nAssumptions are the conditions that are necessary for success, and you believe are true. Your programme needs these conditions in order to succeed, but you believe these conditions already exist – they are not something you bring about with your programme activities.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: Assumption: Principal vacancy exists, and schools meet the eligibility criteria in order to avail of the allowance.\n\nEvaluative thinking prompts: What conditions are required for your initiative to succeed? Who needs to be willing to engage? Challenge your assumptions: if the condition doesn’t exist as expected, do you need to address this in the design/implementation of your initiative?",
    type: "standard"
  },
  "list-1": {
    id: "list-1",
    title: "Inputs",
    taskIds: [],
    tip: "What is an input?\nInputs refer to the resources that are invested in the programme, policy or initiative. The resources include the human, financial, organisational, and community resources available for carrying out a programme's activities.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: $50k annual allowance to school. 1 FTE (full time equivalent) of staff support\n\nEvaluative thinking prompts: This row can also include activities. Activities are the processes, tools, events, and actions that are used to bring about a program's intended changes or results. An example of an activity could be: Create operational guidelines for advertising, selection, appraisal and approval.",
    type: "standard"
  },
  "list-2": {
    id: "list-2",
    title: "Outputs" as ListTitleType,
    taskIds: [],
    tip: "What is an output?\nOutputs refer to what you are doing with the resources invested. Outputs are the direct products of a programme's activities and may include types, levels and targets of services to be delivered by the programme.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: Right school get the right principal at the right time.\n\nEvaluative thinking prompts: Sometimes the line between output and short term outcome can be blurred. It is okay to move cards between the two until you have a group consensus on whether something is an intended result/impact of your initiative (outcome) or a product/service that the initiative will deliver (output).",
    type: "standard"
  },
  "list-3": {
    id: "list-3",
    title: "Short Term Outcome" as ListTitleType,
    taskIds: [],
    tip: "What is a short term outcome?\nRefer to changes in awareness, knowledge, attitudes, skills, opinions, aspirations and motivations. They are measured at the end of the programme, project or any initiative. It can be directly tied to the intervention.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: Quality leadership is initiated with community contribution\n\nEvaluative thinking prompts: Don’t forget to add Indicators to your short term outcomes. Indicators are measurable information used to determine if a programme is being implemented as expected and achieving their outcomes. The choice of indicators will often inform the rest of the evaluation plan, including evaluation methods, data analysis, and reporting",
    type: "outcome"
  },
  "list-4": {
    id: "list-4",
    title: "Medium Term Outcome" as ListTitleType,
    taskIds: [],
    tip: "What is a medium term outcome?\nRefer to changes in behaviour, practice, decision-making, policies and social action. Depending on the complexity of the programme, medium term outcomes are usually measured within 2-3 years of implementation.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: Quality leadership has positive impact on teaching practice.\n\nEvaluative thinking prompts: Think about the different groups of people that will be affected and what that will look like for each group (e.g. learners, whānau, teachers, Kāhui Ako, hapū, iwi, communities).",
    type: "outcome"
  },
  "list-5": {
    id: "list-5",
    title: "Long Term Outcome" as ListTitleType,
    taskIds: [],
    isHighLevel: true,
    tip: "What is a long term outcome?\nRefer to changes in social, economic, civic and environmental conditions. They are measured several years after programme completion. They are hard to directly connect to the intervention. These levels of outcomes are related and build on each other.\n\nExample [from Demo #1 – ‘Principal Recruitment…’]: Distributed leadership is expected\n\nEvaluative thinking prompts: What are the broader conditions that you expect your initiative to influence in some way? Your initiative will not control this long-term outcome completely; there will many influences.",
    type: "standard"
  }
};

export const DEFAULT_BOARD_LISTS_ORDER: ListsOrderType = [
  "list-5",
  "list-4",
  "list-3",
  "list-2",
  "list-1",
  "list-0"
];

export const DEFAULT_TITLE = "My Logic Model Title";

export const DEFAULT_BOARD_TASKS: TasksType = {
  "task-1": {
    id: "task-1",
    label: "Increased equity of achievement and outcomes",
    description: "description goes here",
    indicators: ["indicator-1", "indicator-2", "indicator-6", "indicator-7"]
  },
  "task-2": {
    id: "task-2",
    label:
      "Enhanced school resilience and capability within the schools and across the system",
    description: "description goes here"
  },
  "task-3": {
    id: "task-3",
    label: "Collaborative practice embedded through the pathway",
    description: "description goes here",
    highLevelTaskIDs: ["task-1"],
    indicators: ["indicator-3"]
  },
  "task-4": {
    id: "task-4",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-5": {
    id: "task-5",
    label: "Distributed leadership is embedded",
    highLevelTaskIDs: ["task-1"],
    description: "description goes here"
  },
  "task-6": {
    id: "task-6",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-7": {
    id: "task-7",
    label: "Distributed leadership is embedded",
    highLevelTaskIDs: ["task-2"],
    description: "description goes here"
  },
  "task-8": {
    id: "task-8",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-9": {
    id: "task-9",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-10": {
    id: "task-10",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-11": {
    id: "task-11",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-12": {
    id: "task-12",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-13": {
    id: "task-13",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-14": {
    id: "task-14",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  },
  "task-15": {
    id: "task-15",
    label: "Distributed leadership is embedded",
    description: "description goes here"
  }
};

export const DEFAULT_INDICATORS: IndicatorsType = {
  "indicator-1": {
    id: "indicator-1",
    title: "Indicator 1",
    label: "Label 1",
    description: "description 1"
  },
  "indicator-2": {
    id: "indicator-2",
    title: "Indicator 2",
    label: "Label 2",
    description: "description 2",

    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-3": {
    id: "indicator-3",
    title: "Indicator 3",
    label: "Label 3",
    description: "description 3",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-4": {
    id: "indicator-4",
    title: "Indicator 4",
    label: "Label 4",
    description: "description 4",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-5": {
    id: "indicator-5",
    title: "Indicator 5",
    label: "Label 5",
    description: "description 5",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-6": {
    id: "indicator-6",
    title: "Indicator 6",
    label: "Label 6",
    description: "description 6",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-7": {
    id: "indicator-7",
    title: "Indicator 7",
    label: "Label 7",
    description: "description 7",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  },
  "indicator-8": {
    id: "indicator-8",
    title: "Indicator 8",
    label: "Label 8",
    description: "description 8",
    how: "by car",
    howOften: "fortnightly",
    who: "Delta"
  }
};

export const DEFAULT_OUTCOME_GROUPS = {
  "outcome-group-0": {
    id: "outcome-group-0",
    colour: "No Group"
  },
  "outcome-group-1": {
    id: "outcome-group-1",
    colour: undefined
  },
  "outcome-group-2": {
    id: "outcome-group-2",
    colour: undefined
  },
  "outcome-group-3": {
    id: "outcome-group-3",
    colour: undefined
  },
  "outcome-group-4": {
    id: "outcome-group-4",
    colour: undefined
  },
  "outcome-group-5": {
    id: "outcome-group-5",
    colour: undefined
  }
};

export type TaskSuggestions = { [fieldId: string]: TaskSuggestion[] };

export type TaskSuggestion = {
  value: string | number;
  label: string;
  colour?: string;
};

export default BoardContext;
