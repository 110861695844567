import { BoardStateType } from "./BoardState";
import {
  CreateListPropsType,
  ListsType,
  ListType,
  ListsOrderType
} from "./Board.component";
import { idGen } from "../utils";

const createListReducer = (
  oldState: Pick<BoardStateType, "lists" | "listsOrder">,
  createListProps: CreateListPropsType
) => {
  const { lists, listsOrder } = oldState;
  let { type, title, tip } = createListProps;
  type = type ? type : "standard";
  const id = idGen();
  const newList: ListType = {
    id,
    title,
    taskIds: [],
    tip,
    isHighLevel: false,
    type
  };

  let newState = {
    lists: { ...lists },
    listsOrder: [...listsOrder, newList.id]
  } as CreateListReducerType;
  newState.lists[id] = newList;

  return newState;
};

export default createListReducer;

export type CreateListReducerType = {
  lists: ListsType;
  listsOrder: ListsOrderType;
};
