import { TaskSuggestions, TaskSuggestion } from "./BoardState";

// This data was ported from the previous app from "Suggestions.js"

const outcomeLevel: TaskSuggestion[] = [
  { value: "High", label: "Long Term Outcome" },
  { value: "Intermediate", label: "Medium Term Outcome" },
  { value: "Low", label: "Short Term Outcome" },
  { value: "Output", label: "Output" },
  { value: "Input", label: "Input" }
];

const dataSourceOptions: TaskSuggestion[] = [
  { value: "MoE", label: "MoE" },
  { value: "MPI", label: "MPI" },
  { value: "TEC", label: "TEC" }
];

const highOutcomeName: TaskSuggestion[] = [
  {
    value: "Improved outcomes for students",
    label: "Improved outcomes for students"
  },
  {
    value: "Positive impact on workforce",
    label: "Positive impact on workforce"
  },
  {
    value: "Positive impacts on schools",
    label: "Positive impacts on schools"
  },
  {
    value: "Positive impacts on practice",
    label: "Positive impacts on practice"
  },
  {
    value: "Students enter workforce or further study",
    label: "Students enter workforce or further study"
  },
  {
    value: "Equity & Excellence in Education",
    label: "Equity & Excellence in Education"
  },
  {
    value: "High Quality Teaching and Schooling",
    label: "High Quality Teaching and Schooling"
  },
  {
    value: "Informed Decisions by Teachers",
    label: "Informed Decisions by Teachers"
  }
];
const intermediateOutcomeName: TaskSuggestion[] = [
  {
    value: "PRA recognised as challenging and rewarding pathway",
    label: "PRA recognised as challenging and rewarding pathway"
  },
  { value: "School improvement", label: "School improvement" },
  {
    value: "Reduced incidence of statutory interventions",
    label: "Reduced incidence of statutory interventions"
  },

  {
    value:
      "Vote Education (and individual appropriations, programmes, etc) is funded at the appropriate level",
    label:
      "Vote Education (and individual appropriations, programmes, etc) is funded at the appropriate level"
  },
  {
    value: "Teaching is Enhanced Through Learning",
    label: "Teaching is Enhanced Through Learning"
  },
  {
    value:
      "The Sector Sees Value in EDK's Outputs (Hence Happy to Share Data With Us)",
    label:
      "The Sector Sees Value in EDK's Outputs (Hence Happy to Share Data With Us)"
  },
  {
    value:
      "There are fewer emergencies (because we plan ahead & see things coming)",
    label:
      "There are fewer emergencies (because we plan ahead & see things coming)"
  },
  {
    value: "Policy design and decisions",
    label: "Policy design and decisions"
  },
  {
    value: "Evidence available and accessible",
    label: "Evidence available and accessible"
  }
];
const lowOutcomeName: TaskSuggestion[] = [
  {
    value: "Improved school climate and culture",
    label: "Improved school climate and culture"
  },
  {
    value: "Improved governance and management capability",
    label: "Improved governance and management capability"
  },
  {
    value: "Change effectively implemented",
    label: "Change effectively implemented"
  },
  {
    value: "Mitigation of risks to the school",
    label: "Mitigation of risks to the school"
  },
  {
    value: "Improved Collaboration Between Teams in EDK",
    label: "Improved Collaboration Between Teams in EDK"
  },
  {
    value: "Collaboration with the rest of the Ministry  ",
    label: "Collaboration with the rest of the Ministry  "
  },
  { value: "Monitoring Delivery", label: "Monitoring Delivery" },
  { value: "Achieving Deadlines", label: "Achieving Deadlines" },
  {
    value: "Understanding Drivers of Population",
    label: "Understanding Drivers of Population"
  },
  {
    value:
      "Engaged with Sector to Understand Context we are Measuring & (one of) Customer we are Producing Evidence for. ",
    label:
      "Engaged with Sector to Understand Context we are Measuring & (one of) Customer we are Producing Evidence for. "
  },
  {
    value:
      "All Output is Free of Material Error at the Point at Which it is Submitted for Sign-out, Published on Ed Counts or Provided to Customers as Final",
    label:
      "All Output is Free of Material Error at the Point at Which it is Submitted for Sign-out, Published on Ed Counts or Provided to Customers as Final"
  }
];
const outputName: TaskSuggestion[] = [
  { value: "Boards access the PRA", label: "Boards access the PRA" },
  {
    value: "Highly effective, experienced principals apply",
    label: "Highly effective, experienced principals apply"
  },
  {
    value:
      "Clear goals and effective relationships established between stakeholders",
    label:
      "Clear goals and effective relationships established between stakeholders"
  },
  {
    value: "Change process and plan established with supports in place",
    label: "Change process and plan established with supports in place"
  },

  { value: "The Evaluation App", label: "The Evaluation App" },
  { value: "Forecasts ECE/School Roll", label: "Forecasts ECE/School Roll" },
  { value: "Causal Analysis", label: "Causal Analysis" },
  {
    value: "Evaluation Plans for all Project Initiatives ",
    label: "Evaluation Plans for all Project Initiatives "
  },
  { value: "Descriptive Statistics", label: "Descriptive Statistics" },
  {
    value:
      "We Have a set of Evidence Priorities & a Route Map for Delivering Them",
    label:
      "We Have a set of Evidence Priorities & a Route Map for Delivering Them"
  }
];
const inputName: TaskSuggestion[] = [
  {
    value: "Robust criteria and application processes",
    label: "Robust criteria and application processes"
  },
  {
    value:
      "Resources and supports for boards, PRA principals, and local ministry staff",
    label:
      "Resources and supports for boards, PRA principals, and local ministry staff"
  },
  {
    value: "Clear relationship of the PRA to other interventions available",
    label: "Clear relationship of the PRA to other interventions available"
  },
  {
    value:
      "Across EDK, we Know Exactly who to go, for What, and who Needs our Outputs",
    label:
      "Across EDK, we Know Exactly who to go, for What, and who Needs our Outputs"
  },
  {
    value: "Our People are Highly Capable Analytical Machines",
    label: "Our People are Highly Capable Analytical Machines"
  }
];
const indicatorTitle: TaskSuggestion[] = [
  {
    value: "Significant positive shifts in student achievement",
    label: "Significant positive shifts in student achievement"
  },
  { value: "Improved enrolment", label: "Improved enrolment" },
  { value: "Improved attendance", label: "Improved attendance" },
  {
    value: "Status of teaching enhanced",
    label: "Status of teaching enhanced"
  },
  {
    value: "Improved recruitment and retention",
    label: "Improved recruitment and retention"
  },
  {
    value: "Stronger career paths established",
    label: "Stronger career paths established"
  },
  {
    value: "Enhanced school resilience and capability",
    label: "Enhanced school resilience and capability"
  },
  {
    value: "High levels of cooperation and collaboration across schools",
    label: "High levels of cooperation and collaboration across schools"
  },
  {
    value: "Knowledge shared, evolving, accessible, and used",
    label: "Knowledge shared, evolving, accessible, and used"
  },
  {
    value: "ERO review cycle interval increases",
    label: "ERO review cycle interval increases"
  },
  { value: "Staff turnoff stabilises", label: "Staff turnoff stabilises" },
  {
    value: "Greater participation of kahui ako",
    label: "Greater participation of kahui ako"
  },
  {
    value: "Changes in classroom practice",
    label: "Changes in classroom practice"
  },
  { value: "Improved board stability", label: "Improved board stability" },
  {
    value: "Stakeholders more confident about the value of the school",
    label: "Stakeholders more confident about the value of the school"
  },
  {
    value: "Professional accountability and learning visible in school culture",
    label: "Professional accountability and learning visible in school culture"
  },
  {
    value: "Improvements in student and staff wellbeing",
    label: "Improvements in student and staff wellbeing"
  },
  {
    value: "Clear educational focus emerging",
    label: "Clear educational focus emerging"
  },
  {
    value: "Boards getting strong principal support",
    label: "Boards getting strong principal support"
  },
  {
    value: "Board responding more effectively to more challenging situations",
    label: "Board responding more effectively to more challenging situations"
  },
  {
    value: "Planning to manage sustainable change visible",
    label: "Planning to manage sustainable change visible"
  },
  {
    value:
      "School wide management and instructional leadership focused on student achievement",
    label:
      "School wide management and instructional leadership focused on student achievement"
  },
  { value: "Increased demand for PLD", label: "Increased demand for PLD" },
  {
    value:
      "Skills, capabilities, systems, and resources in place for school transformation",
    label:
      "Skills, capabilities, systems, and resources in place for school transformation"
  },
  {
    value: "Strengthened board self-review processes",
    label: "Strengthened board self-review processes"
  },
  {
    value: "Conditions for improved classroom practise visible",
    label: "Conditions for improved classroom practise visible"
  },
  {
    value: "PRA principal continues to meet allowance criteria",
    label: "PRA principal continues to meet allowance criteria"
  },
  {
    value: "School meets fewer of the PRA eligibility criteria",
    label: "School meets fewer of the PRA eligibility criteria"
  },
  {
    value: "Schools meeting the criteria for the PRA program",
    label: "Schools meeting the criteria for the PRA program"
  },
  {
    value: "Sufficient supply of eligible applicants",
    label: "Sufficient supply of eligible applicants"
  },
  {
    value: "Performance agreements reflect specific needs of school",
    label: "Performance agreements reflect specific needs of school"
  },
  {
    value: "Collaborative review of the pressures existing",
    label: "Collaborative review of the pressures existing"
  },
  {
    value:
      "Change plan integrated into the annual planning and accountability documents",
    label:
      "Change plan integrated into the annual planning and accountability documents"
  },
  { value: "Schools are identified", label: "Schools are identified" },
  {
    value: "Having an expert panel to assess applicant eligibility",
    label: "Having an expert panel to assess applicant eligibility"
  },
  {
    value: "Operation guidelines for stakeholders available",
    label: "Operation guidelines for stakeholders available"
  },
  {
    value:
      "Role of the PRA relative to other supports for schools in needs is clearly defined and understood",
    label:
      "Role of the PRA relative to other supports for schools in needs is clearly defined and understood"
  },
  {
    value: "International Reputation for Research and Evaluation",
    label: "International Reputation for Research and Evaluation"
  },
  { value: "Delivery, Trust and Brand", label: "Delivery, Trust and Brand" },
  { value: "A Learning System", label: "A Learning System" }
];
const dataMeasurement: TaskSuggestion[] = [
  { value: "Years", label: "Years" },
  { value: "Student Achievement", label: "Student Achievement" },
  { value: "Percentage of Attendence", label: "Percentage of Attendence" }
];
const dataCollectionMethod: TaskSuggestion[] = [
  { value: "Survey", label: "Survey" },
  { value: "Database", label: "Database" },
  { value: "School Marks", label: "School Marks" }
];
const meansOfVerification: TaskSuggestion[] = [
  { value: "Survey", label: "Survey" },
  { value: "Database", label: "Database" },
  { value: "School Marks", label: "School Marks" }
];
const personResponsibleForCollection: TaskSuggestion[] = [
  { value: "Survey", label: "Survey" },
  { value: "Database", label: "Database" },
  { value: "School Marks", label: "School Marks" }
];
const costOfDataCollection: TaskSuggestion[] = [
  { value: "$1000", label: "$1000" },
  { value: "$2000", label: "$2000" },
  { value: "$3000", label: "$3000" }
];
const whenWillCollectionTakePlace: TaskSuggestion[] = [
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" }
];
const measurementOfVerification: TaskSuggestion[] = [
  { value: "R", label: "Red" },
  { value: "G", label: "Green" },
  { value: "B", label: "Blue" }
];
const indicatorDescription: TaskSuggestion[] = [];

export const DEFAULT_TASK_SUGGESTIONS: TaskSuggestions = {
  indicatorDescription,
  outcomeLevel,
  High: highOutcomeName,
  Intermediate: intermediateOutcomeName,
  Low: lowOutcomeName,
  Output: outputName,
  Input: inputName,
  dataCollectionMethod,
  dataMeasurement,
  meansOfVerification,
  personResponsibleForCollection,
  dataSource: dataSourceOptions,
  cost: costOfDataCollection,
  whenWillDataBeCollected: whenWillCollectionTakePlace,
  measurementOfVerification: measurementOfVerification,
  indicatorTitle: indicatorTitle
};
