import React, { useState } from "react";
import { idGen } from "../utils";
import Field from "./Field";
import { FieldInputTextBaseProps } from "./FieldInputText";
import "./FieldInputText.scss";

type ReactHookFormInputProps = FieldInputTextBaseProps & {
  placeholder?: string | null;
};

export const InputText_ReactHookForm = React.forwardRef(
  (
    {
      label,
      name,
      onChange,
      className: propClassName,
      maxLength,
      onKeyPress,
      placeholder = ""
    }: ReactHookFormInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const className = `field__input-text field-padding ${propClassName}`;
    const [id] = useState(idGen());
    return (
      <Field
        id={id}
        label={label}
        value=""
        readOnly={false}
        useCustomViewMode={false}
      >
        <input
          tabIndex={0}
          id={id}
          onChange={onChange}
          type="text"
          className={className}
          ref={ref}
          name={name}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          placeholder={placeholder || ""}
        />
      </Field>
    );
  }
);

export default InputText_ReactHookForm;
