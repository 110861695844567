export function fixHeadingOnXAxis() {
  // This essentially does a position:fixed on one axis
  // It's done outside of React because React would add
  // nothing of value, and scroll handlers are notoriously
  // sluggish so we'll bypass React in this particular case

  let headings: NodeList;
  let lastScrollX: number;

  window.addEventListener(
    "scroll",
    e => {
      if (!headings) return;
      // If there's been no change on the X axis then exit early
      if (lastScrollX === window.scrollX) return;

      for (let i = 0; i < headings.length; i++) {
        const heading = headings[i] as HTMLElement;
        heading.style.left = window.scrollX + "px";
      }
      lastScrollX = window.scrollX;
    },
    true
  );

  const updateQueryAll = () => {
    // If React makes new nodes (a rare occasion in this app)
    // then we'll need to update 'headings' with that
    headings = document.querySelectorAll(".boardlist__heading-container");
    setTimeout(updateQueryAll, 1000);
  };

  updateQueryAll();
}
