import React, { useState, ChangeEventHandler } from "react";
import classnames from "classnames";
import { idGen } from "../utils";
import Field, { FieldContext, FieldContextType } from "./Field";
import "./FieldTextarea.scss";

type Props = {
  label: React.ReactNode;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  noresize?: boolean;
  className?: string;
  placeholder?: string | null;
};

const Textarea = ({
  label,
  className: classes = "",
  value,
  onChange,
  noresize,
  placeholder
}: Props) => {
  const [id] = useState(idGen());
  const [hasHadInitialFocus, setInitialFocus] = useState(false);

  return (
    <Field
      id={id}
      readModeClassName="field__textarea--readonly"
      label={label}
      readOnly={false}
      useCustomViewMode={true}
      value={value}
      placeholder={placeholder}
      isTextArea={true}
    >
      <FieldContext.Consumer>
        {({ onBlur, selectionPosition }: FieldContextType) => {
          return (
            <div className="field__textarea-container">
              <textarea
                onBlur={(e) => {
                  setInitialFocus(false);
                  onBlur(e);
                }}
                ref={(inputRef) => {
                  if (inputRef) {
                    inputRef.focus();
                    if (!hasHadInitialFocus) {
                      setInitialFocus(true);
                      inputRef.selectionStart = selectionPosition;
                      inputRef.selectionEnd = selectionPosition;
                    }
                  }
                }}
                id={id}
                value={value}
                onChange={onChange}
                className={classnames(
                  "field__textarea field-padding",
                  classes,
                  {
                    "field__textarea--noresize": noresize
                  }
                )}
                placeholder={placeholder || undefined}
              />
            </div>
          );
        }}
      </FieldContext.Consumer>
    </Field>
  );
};

export default Textarea;
