import pick from "lodash/pick";
import { DropResult } from "react-beautiful-dnd";
import { BoardStateType } from "../Board/BoardState";

const dragListEndReducer = (
  oldState: Pick<BoardStateType, "listsOrder">, // we only care about the Lists part of the state so we'll pick just lists to make it easier to mock this object
  result: FilteredResult
): BoardStateType["listsOrder"] | undefined => {
  const { listsOrder } = oldState;
  const { destination, source } = result;

  if (!destination) {
    // dropped outside of lists
    return listsOrder;
  }

  if (
    // dropped at same place
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return listsOrder;
  }


  const newListsOrder = moveList(listsOrder, source.index, destination.index);
  return newListsOrder;
};

export const moveList = (
  listsOrder: string[],
  fromIndex: number,
  toIndex: number
) => {
  const newListsOrder = [...listsOrder];
  const removedItem = newListsOrder.splice(fromIndex, 1)[0];
  newListsOrder.splice(toIndex, 0, removedItem);

  return newListsOrder;
};

export type FilteredResult = Pick<
  DropResult,
  "destination" | "source" | "draggableId"
>;

export const filterResult = (result: DropResult): FilteredResult => {
  return pick(result, ["destination", "source", "draggableId"]);
};

export default dragListEndReducer;
