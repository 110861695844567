import React from "react";
import BoardContext, { BoardContextType } from "../Board/BoardState";

import FieldTextArea from "../Field/FieldTextarea";
import FieldInputTextPlain from "../Field/FieldInputTextPlain";
import "./EvaluationView.scss";

const ENTER_KEY = 13;
const disableNewlines = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const keyCode = event.keyCode || event.which;

  if (keyCode === ENTER_KEY) {
    if (event.preventDefault) event.preventDefault();
  }
};

const EvaluationModal = () => {
  return (
    <BoardContext.Consumer>
      {(boardValue: BoardContextType) => {
        const {
          evaluationTitle,
          evaluationDescription,
          onSetEvaluationTitle,
          onSetEvaluationDescription
        } = boardValue;

        const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
          onSetEvaluationTitle(event.target.value);
        };

        const onChangeDescription = (
          event: React.ChangeEvent<HTMLTextAreaElement>
        ) => {
          onSetEvaluationDescription(event.target.value);
        };

        return (
          <div className="filtermodalcard">
            <h2 className="modal__heading">Project Details</h2>

            <FieldInputTextPlain
              label="Title"
              name="title"
              className="evaluation__title"
              value={evaluationTitle}
              onChange={onChangeTitle}
              onKeyPress={disableNewlines}
            />

            <FieldTextArea
              label="Description"
              className="evaluation__description"
              value={evaluationDescription}
              onChange={onChangeDescription}
              noresize
            />
          </div>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default EvaluationModal;
