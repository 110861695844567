import { TaskType } from "./Board.component";

const updateHighLevelLink = (
  taskToBeUpdated: TaskType,
  highLevelTaskID: string,
  shouldAdd: boolean
): TaskType => {
  const list = taskToBeUpdated.highLevelTaskIDs
    ? [...taskToBeUpdated.highLevelTaskIDs]
    : [];
  const foundIndex = list.indexOf(highLevelTaskID);

  if (shouldAdd) {
    if (foundIndex === -1) {
      list.push(highLevelTaskID);
    }
  } else {
    if (foundIndex !== -1) {
      list.splice(foundIndex, 1);
    }
  }

  const updatedTask = {
    ...taskToBeUpdated
  } as TaskType;

  updatedTask.highLevelTaskIDs = list;

  return updatedTask;
};

export default updateHighLevelLink;
