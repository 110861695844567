import React, { ButtonHTMLAttributes, MouseEvent } from "react";
import classnames from "classnames";
import "./FieldButton.scss";

type Props = {
  children: React.ReactNode;
  type?: Pick<ButtonHTMLAttributes<HTMLButtonElement>, "type">;
  onClick?: (event: React.MouseEvent) => void;
  level?: "warning" | "confirm";
  icon?: "delete" | "add" | "download";
  disabled?: boolean;
};

const Button = ({
  children,
  onClick,
  level,
  icon,
  disabled = false
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={classnames("field__button", {
        [`field__button--level-${level}`]: level,
        "field__button--has-icon": icon,
        [`field__button--has-icon-${icon}`]: icon
      })}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
