import React from "react";
import { IndicatorType } from "../Board/Board.component";
import InputTextPlain from "../Field/FieldInputTextPlain";
import InputTextArea from "../Field/FieldTextarea";
import FieldButton from "../Field/FieldButton";
import { getGloss, GLOSS } from "../Field/getGloss";

type Props = {
  indicator: IndicatorType;
  forceEditMode: boolean;
  onChange: (id: string, updatedProps: Partial<IndicatorType>) => void;
  onRemoveIndicatorFromList: (id: string) => void;
};

const IndicatorEdit = ({
  indicator,
  onChange,
  forceEditMode,
  onRemoveIndicatorFromList
}: Props) => {
  // const metadata = indicator.metadata ? indicator.metadata : [];

  const onNameChange = (event: any) => {
    onChange(indicator.id, { title: event.target.value });
  };
  const onLabelChange = (event: any) => {
    onChange(indicator.id, { label: event.target.value });
  };

  const onDescriptionChange = (event: any) => {
    onChange(indicator.id, { description: event.target.value });
  };
  const onWhoChange = (event: any) => {
    onChange(indicator.id, { who: event.target.value });
  };
  const onHowOftenChange = (event: any) => {
    onChange(indicator.id, { howOften: event.target.value });
  };
  const onHowChange = (event: any) => {
    onChange(indicator.id, { how: event.target.value });
  };

  return (
    <div>
      <InputTextPlain
        label="Label (max 20 chars)"
        name="label"
        value={indicator.label}
        onChange={onLabelChange}
        maxLength={20}
        forceEditMode={forceEditMode ? forceEditMode : false}
        placeholder={getGloss(GLOSS.CARD_INDICATOR_LABEL_PLACEHOLDER)}
      />
      <InputTextPlain
        label="Title"
        name="title"
        value={indicator.title}
        onChange={onNameChange}
        placeholder={getGloss(GLOSS.CARD_INDICATOR_TITLE_PLACEHOLDER)}
      />

      <InputTextArea
        label="Description"
        value={indicator.description}
        onChange={onDescriptionChange}
        placeholder={getGloss(GLOSS.CARD_INDICATOR_DESCRIPTION_PLACEHOLDER)}
        noresize
      />
      <InputTextArea
        label="Who collects?"
        value={indicator.who || ""}
        onChange={onWhoChange}
        placeholder={getGloss(GLOSS.CARD_INDICATOR_WHO_COLLECTS_PLACEHOLDER)}
      />
      <InputTextArea
        label="How to collect?"
        value={indicator.how || ""}
        onChange={onHowChange}
        placeholder={getGloss(GLOSS.CARD_INDICATOR_HOW_TO_COLLECT_PLACEHOLDER)}
      />
      <InputTextArea
        label="How often to collect?"
        value={indicator.howOften || ""}
        onChange={onHowOftenChange}
        placeholder={getGloss(
          GLOSS.CARD_INDICATOR_HOW_OFTEN_TO_COLLECT_PLACEHOLDER
        )}
      />
      <hr />

      <FieldButton
        onClick={() => {
          const result = window.confirm(
            `Confirm deleting "${indicator.title}"?`
          );
          if (result) {
            onRemoveIndicatorFromList(indicator.id);
          }
        }}
        icon="delete"
      >
        Delete Indicator
      </FieldButton>
    </div>
  );
};

export default IndicatorEdit;
