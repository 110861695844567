import { IndicatorType } from "../Board/Board.component";

const updateIndicatorReducer = (
  oldState: IndicatorType,
  newIndicator: Partial<IndicatorType>
): IndicatorType => {
  return { ...oldState, ...newIndicator };
};

export default updateIndicatorReducer;
