import pick from "lodash/pick";
import { DropResult } from "react-beautiful-dnd";
import { ListType, ListsType } from "./Board.component";
import { BoardStateType } from "./BoardState";

const dragEndReducer = (
  oldState: Pick<BoardStateType, "lists">, // we only care about the Lists part of the state so we'll pick just lists to make it easier to mock this object
  result: FilteredResult
): BoardStateType["lists"] | undefined => {
  const { lists } = oldState;
  const { destination, source } = result;

  if (!destination) {
    // dropped outside of any List
    return;
  }

  if (
    // dropped at same place
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const startList: ListType = lists[source.droppableId];
  const finishList: ListType = lists[destination.droppableId];
  const isSameList = startList === finishList;

  // move within same list
  if (isSameList) {
    return moveWithinList(startList, source.index, destination.index, lists);
  }

  // Moving from one list to another
  return moveToList(
    startList,
    source.index,
    finishList,
    destination.index,
    lists
  );
};

export const moveWithinList = (
  startList: ListType,
  from: number,
  to: number,
  lists: ListsType
) => {
  const orderedTasksInList = moveTaskWithinList(startList.taskIds, from, to);
  const updatedList = updateList(startList, orderedTasksInList);
  const collection = updateCollection(lists, updatedList);
  return collection;
};

export const moveToList = (
  startList: ListType,
  originalIndex: number,
  finishList: ListType,
  destinationIndex: number,
  lists: ListsType
) => {
  //remove from previous List

  const taskId = startList.taskIds[originalIndex];
  const startTaskIds: string[] = removeFromList(
    startList.taskIds,
    originalIndex
  );
  const newStart: ListType = updateList(startList, startTaskIds);

  // add to current List
  const finishTaskIds = addToList(
    taskId,
    finishList.taskIds,
    finishList,
    destinationIndex
  );
  const newFinish = updateList(finishList, finishTaskIds.taskIds);

  return {
    ...lists,
    [newStart.id]: newStart,
    [newFinish.id]: newFinish
  };
};

export const moveTaskWithinList = (
  taskList: string[],
  fromIndex: number,
  toIndex: number
) => {
  const newTaskIds = [...taskList];
  const removedItem = newTaskIds.splice(fromIndex, 1)[0];
  newTaskIds.splice(toIndex, 0, removedItem);

  return newTaskIds;
};

export const addToList = (
  taskId: string,
  taskIds: string[],
  destinationList: ListType,
  position: number
) => {
  const finishTaskIds = Array.from(taskIds);
  finishTaskIds.splice(position, 0, taskId);

  return {
    ...destinationList,
    taskIds: finishTaskIds
  };
};

export const updateList = (list: ListType, taskList: string[]): ListType => {
  return {
    ...list,
    taskIds: taskList
  };
};

export const updateCollection = (
  list: ListsType,
  updatedList: ListType
): ListsType => {
  return {
    ...list,
    [updatedList.id]: updatedList
  };
};

export const removeFromList = (list: string[], index: number): string[] => {
  const totalList = [...list];
  totalList.splice(index, 1);
  return totalList;
};

export type FilteredResult = Pick<
  DropResult,
  "destination" | "source" | "draggableId"
>;

export const filterResult = (result: DropResult): FilteredResult => {
  return pick(result, ["destination", "source", "draggableId"]);
};

export default dragEndReducer;
