import React from "react";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import { IndicatorsType, TaskWithStyleType } from "../Board.component";
import AppState, { ModalsType } from "../../AppState";
import IndicatorList from "./IndicatorList.component";
import IndicatorLabel from "./IndicatorLabel.component";
import "./BoardCard.scss";

type ContainerProps = {
  task: TaskWithStyleType;
  index: number;
  isNewTask: boolean;
  indicators: IndicatorsType;
  isDragDisabled?: boolean;
  showIndicators: boolean;
  isHighLevel?: boolean;
};

const BoardCardContainer = (props: ContainerProps) => (
  <AppState.Consumer>
    {(value) => {
      return (
        <BoardCard
          {...{
            ...props,
            modals: value.modals
          }}
        />
      );
    }}
  </AppState.Consumer>
);

type Props = ContainerProps & {
  modals: ModalsType;
};

const BoardCard = ({
  task,
  index,
  modals,
  isNewTask,
  indicators,
  isDragDisabled,
  showIndicators,
  isHighLevel
}: Props) => {
  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={`${task.id}${index}`}
      index={index}
    >
      {(provided, snapshot) => {
        const onCardClick = () => {
          openModal();
        };
        const openModal = (indicatorID: string | undefined = undefined) => {
          if (!modals.setModalOpen) return;
          modals.setModalOpen({
            type: "ViewOrEditCard",
            targetId: task.id,
            indicatorID
          });
        };
        const indicatorIDs: string[] = task.indicators || [];
        let extraStyle = {
          "boardcard--dragging": snapshot.isDragging,
          bounceIn: isNewTask
        };

        const onIndicatorClick = (e: React.MouseEvent, id: string) => {
          e.stopPropagation();
          openModal(id);
        };

        const widthForColours = 30;
        let borderStyle: any = {
          paddingLeft: `${widthForColours}px`,
          border: 0
        };
        if (task.cardColour) {
          const colourWidth = widthForColours / task.cardColour.length;

          let gradient = "to right";
          if (isHighLevel) {
            borderStyle.border = `4px solid ${task.cardColour[0]}`;
          } else {
            const sortedColours = [...task.cardColour];
            // sort so colours always append in same order
            sortedColours.sort();

            sortedColours.forEach((colour, index) => {
              gradient += `,${colour} ${index * colourWidth}px,${colour} ${
                (index + 1) * colourWidth
              }px `;
            });
            const lastStop = (sortedColours.length - 1) * colourWidth;
            borderStyle.backgroundImage = `linear-gradient( ${gradient} ,#FFFFFF ${lastStop}px)`;
            borderStyle.paddingLeft = `${lastStop + colourWidth}px`;
          }
        }
        return (
          <div
            id={taskIdToCardId(task.id)}
            onClick={onCardClick}
            className={classnames("boardcard", extraStyle)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...borderStyle
            }}
          >
            <div
              className="boardcard__label"
              dangerouslySetInnerHTML={{ __html: task.label }}
            ></div>
            <div className="boardcard__print-description">
              {task.description}
            </div>
            {showIndicators && (
              <IndicatorList>
                {indicatorIDs.map((indicatorID: string) => {
                  return (
                    <IndicatorLabel
                      onClick={onIndicatorClick}
                      key={indicatorID}
                      {...indicators[indicatorID]}
                    />
                  );
                })}
              </IndicatorList>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};

export const taskIdToCardId = (taskId: string): string => `card-${taskId}`;

export const scrollToCard = (cardId: string) => {
  setTimeout(
    () => {
      const toolbarSelector = ".toolbar";
      const toolbar = document.querySelector(toolbarSelector);
      const id = taskIdToCardId(cardId);
      const newCardElement = document.getElementById(id);

      if (!newCardElement || !toolbar) {
        console.error(
          `Unable to scroll to new card with id="${id}" or find toolbar with "${toolbarSelector}"`
        );
        return;
      }

      const windowRect = document.body.getBoundingClientRect();

      const rect = newCardElement.getBoundingClientRect();

      const offsetY = rect.top - windowRect.top;
      const offsetX = rect.left - windowRect.left;
      //offset the y to ensure the header isn't covering it up
      const headerOffset = 80;
      window.scrollTo(offsetX, offsetY - headerOffset);
    },
    250 // must be enough time for React to render card
  );
};

export default BoardCardContainer;
