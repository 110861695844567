import React, { useState, Fragment, useEffect } from "react";
import useForm from "react-hook-form";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";

import "../../Field/ReactAccessibleAccordion.scss";
import FieldButton from "../../Field/FieldButton";

import BoardContext, { BoardContextType } from "../BoardState";
import { IndicatorType, ListType, TaskWithStyleType } from "../Board.component";
import InputTextReactHookForm from "../../Field/FieldInputTextReactHookForm";

import "./BoardCardView.scss";
import FieldTextArea from "../../Field/FieldTextarea";
import FieldSelect from "../../Field/FieldSelect";
import { getListDetailsFromTask } from "../Board.utils";
import InputColour from "../../Field/FieldInputColour";

import IndicatorEdit from "../../Indicator/IndicatorEdit.form.component";
import { idGen } from "../../utils";
import FieldCheckList from "../../Field/FieldCheckList";
import { getGloss, GLOSS } from "../../Field/getGloss";

const DEFAULT_HIGH_LEVEL_COLOUR = "#00FF00";
const FIELDS_LABEL = "label";

type Props = {
  taskID: string;
  indicatorID?: string | undefined;
  isNew?: boolean;
  targetListID?: string;
  closeModal: Function;
  setCanClose: (value: boolean) => void;
};

const BoardModalCard = ({
  taskID,
  targetListID,
  closeModal,
  isNew = false,
  setCanClose,
  indicatorID
}: Props) => {
  const [archivedListID, setArchivedListID] = useState();

  const formResult = useForm();
  let { register, triggerValidation, errors, getValues, setValue } =
    formResult || {
      register: undefined,
      triggerValidation: undefined,
      errors: undefined,
      getValues: undefined,
      setValue: undefined
    };

  const hasErrors = Object.values(errors).length > 0;
  const isArchived = archivedListID ? true : false;

  useEffect(() => {
    if (indicatorID) {
      const selectedIndicator = document.getElementById(
        `accordion__panel-${indicatorID}`
      );

      if (selectedIndicator) {
        selectedIndicator.scrollIntoView();
      }
    }
  }, [indicatorID]);

  setCanClose(isNew || isArchived || !hasErrors);

  const [RAAUpdateID, setRAAUpdateID] = useState(0);

  return (
    <BoardContext.Consumer>
      {(boardValue: BoardContextType) => {
        const {
          lists,
          onAddIndicatorToList,
          onUpdateIndicator,
          onAddHighLevelLink,
          onRemoveHighLevelLink,
          onRemoveIndicatorFromList
        } = boardValue;
        const task: undefined | TaskWithStyleType = boardValue.tasks[taskID];
        const allIndicators: IndicatorType[] = Object.values(
          boardValue.indicators
        );
        const {
          listID = targetListID,
          highLevelListID = "",
          list
        } = getListDetailsFromTask(taskID, lists);
        const listArray = Object.values(
          boardValue.lists
        ).reverse() as ListType[];

        if (getValues()[FIELDS_LABEL] !== task.label) {
          setValue(FIELDS_LABEL, task ? task.label : "");
        }

        const onAddIndicatorClick = () => {
          onAddIndicatorToList(
            {
              description: "",
              label: "",
              title: "",
              id: idGen()
            },
            task.id
          );

          setRAAUpdateID(RAAUpdateID + 1);
        };

        const onLabelChange = (event: any) => {
          triggerValidation();
          boardValue.onUpdateCard(task.id, {
            label: getValues()[FIELDS_LABEL]
          });
        };
        const onDescriptionChange = (event: any) => {
          boardValue.onUpdateCard(task.id, { description: event.target.value });
        };

        const onArchiveClick = () => {
          // add undo action
          const fromListID = boardValue.onArchiveCard(task.id);
          setArchivedListID(fromListID);
        };

        const onSaveClick = async () => {
          const valid = await triggerValidation();
          if (valid) {
            closeModal();
          }
        };

        const onCancelClick = () => {
          boardValue.onArchiveCard(task.id);
          closeModal();
        };
        const onUndoClick = () => {
          //  undo action
          boardValue.onMoveTaskToListReducer(task.id, archivedListID);
          setArchivedListID(undefined);
        };

        const setTargetListID = (listID: string) => {
          boardValue.onMoveTaskToListReducer(taskID, listID);
        };

        if (!task) {
          return <span>Unrecognised {taskID}</span>;
        }

        let taskHeader: string = "";

        const currentList: ListType | undefined = listArray.find(
          (list) => list.id === listID
        );
        // theoraticly there is always a list!
        if (currentList) {
          taskHeader = isNew ? `New ${currentList.title}` : currentList.title;
        } else {
          taskHeader = "New";
        }

        const titleLabel = isNew ? "Title" : "";
        const indicatorsOfTask = allIndicators.filter(
          ({ id }) => task.indicators && task.indicators.indexOf(id) !== -1
        );

        const onUpdateCardColour = (value: string) => {
          boardValue.onUpdateCard(task.id, { cardColour: [value] });
        };
        const cardColour = task.cardColour
          ? task.cardColour[0]
          : DEFAULT_HIGH_LEVEL_COLOUR;

        const closeModalFooterButton = (
          <button
            onClick={() => {
              closeModal();
            }}
            className="field__button field__button--success field__button--right"
          >
            Done
          </button>
        );

        const showHighLevelCheckList =
          listID !== "" && listID !== highLevelListID;

        const checkListItems = boardValue.lists[highLevelListID].taskIds.map(
          (taskID: string) => {
            const label = boardValue.tasks[taskID].label;
            const checked = task.highLevelTaskIDs
              ? task.highLevelTaskIDs.indexOf(taskID) > -1
              : false;
            return { label, value: taskID, checked, name: "LTO" };
          }
        );

        const getNoLabelIndicators = (): string[] | undefined => {
          const filtered = indicatorsOfTask.filter(({ label }) => {
            return !label || label.length < 1;
          });
          const ids = filtered.map((indicator) => {
            return indicator.id;
          });
          if (indicatorsOfTask) {
            return ids;
          } else {
            return undefined;
          }
        };

        const getDescriptionPlaceHolderByListId = (
          listId: string | undefined
        ): string => {
          switch (listId) {
            //assumptions
            case "list-0":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_ASSUMPTIONS;
              break;
            //Input
            case "list-1":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST;
              break;
            //new output
            case "list-2":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES;
              break;
            //short term outcome
            case "list-3":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_SHORT_TERM_OUTCOME;
              break;
            //medium term outcome
            case "list-4":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_MEDIUM_TERM_OUTCOME;
              break;
            //lomg term outcome
            case "list-5":
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER_LONG_TERM_OUTCOME;
              break;

            default:
              return GLOSS.CARD_DESCRIPTION_PLACEHOLDER;
              break;
          }
        };
        const getTitlePlaceHolderByListId = (
          listId: string | undefined
        ): string => {
          switch (listId) {
            //assumptions
            case "list-0":
              return GLOSS.CARD_INPUT_PLACEHOLDER_ASSUMPTIONS;
              break;
            //Input
            case "list-1":
              return GLOSS.CARD_INPUT_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST;
              break;
            //new output
            case "list-2":
              return GLOSS.CARD_INPUT_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES;
              break;
            //short term outcome
            case "list-3":
              return GLOSS.CARD_INPUT_PLACEHOLDER_SHORT_TERM_OUTCOME;
              break;
            //medium term outcome
            case "list-4":
              return GLOSS.CARD_INPUT_PLACEHOLDER_MEDIUM_TERM_OUTCOME;
              break;
            //lomg term outcome
            case "list-5":
              return GLOSS.CARD_INPUT_PLACEHOLDER_LONG_TERM_OUTCOME;
              break;

            default:
              return GLOSS.CARD_INPUT_PLACEHOLDER;
              break;
          }
        };

        // if we want to open a specifiv Indicator
        const preExpandedAccordionItems = indicatorID
          ? [indicatorID]
          : // let's open all indicators that don't have a label
            getNoLabelIndicators();

        // setplaceholders based on type
        const type = listID && lists[listID] ? lists[listID].type : "standard";
        const isStandardList = type === "standard";

        console.log({ listID });

        const highLevelName =
          listID && lists[highLevelListID]
            ? lists[highLevelListID].title
            : "Outcome";

        return (
          <div className="boardmodalcard" data-test-card-view-modal>
            <h2 className="modal__heading">{taskHeader}</h2>

            <InputTextReactHookForm
              className={`boardmodalCard__label ${
                hasErrors ? "boardmodalCard__label--error" : ""
              }`}
              label={titleLabel}
              onChange={onLabelChange}
              ref={register({ required: true })}
              name={FIELDS_LABEL}
              placeholder={getGloss(getTitlePlaceHolderByListId(listID))}
            />

            <FieldTextArea
              label="Description"
              className="boardmodalCard__textarea"
              value={task.description}
              onChange={onDescriptionChange}
              noresize
              placeholder={getGloss(getDescriptionPlaceHolderByListId(listID))}
            />
            {!archivedListID && listID && (
              <FieldSelect
                label="List Type"
                value={listID}
                onChange={(event) => {
                  setTargetListID(event.target.value);
                }}
              >
                {listArray.map((list: ListType) => (
                  <option value={list.id} key={list.id}>
                    {list.title}
                  </option>
                ))}
              </FieldSelect>
            )}

            {listID !== "" && listID === highLevelListID && (
              <InputColour
                label={`${highLevelName} border colour`}
                value={cardColour}
                onChange={onUpdateCardColour}
              />
            )}

            {showHighLevelCheckList && (
              <FieldCheckList
                items={checkListItems}
                label={`${highLevelName} Reference`}
                onChange={(id: string, isChecked: boolean) => {
                  if (isChecked) {
                    onAddHighLevelLink(task.id, id);
                  } else {
                    onRemoveHighLevelLink(task.id, id);
                  }
                }}
              />
            )}

            {(!list || (list && list.type === "outcome")) && (
              <Fragment>
                <h3>Indicators:</h3>

                <Accordion
                  allowZeroExpanded
                  preExpanded={preExpandedAccordionItems}
                  // this is a dirty hack because preExpanded is only used on mount
                  // by giving it a new key only when created, we force it to re-creatte and mount
                  // therefor expanding the correct items
                  key={RAAUpdateID}
                >
                  {indicatorsOfTask.map((indicator) => {
                    const forceEditMode =
                      preExpandedAccordionItems &&
                      preExpandedAccordionItems.indexOf(indicator.id) > -1
                        ? true
                        : false;

                    return (
                      <AccordionItem uuid={indicator.id} key={indicator.id}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {indicator.label}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <IndicatorEdit
                            forceEditMode={forceEditMode}
                            indicator={indicator}
                            onChange={onUpdateIndicator}
                            onRemoveIndicatorFromList={
                              onRemoveIndicatorFromList
                            }
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
                <div className="row--right" style={{ marginTop: "1rem" }}>
                  <div className={"row__item"}>
                    <FieldButton onClick={onAddIndicatorClick} icon={"add"}>
                      Add Indicator
                    </FieldButton>
                  </div>
                </div>
              </Fragment>
            )}

            {!archivedListID && !isNew && (
              <div className={"boardmodalCard__buttontray"}>
                {closeModalFooterButton}
                <FieldButton
                  onClick={onArchiveClick}
                  level="warning"
                  icon={"delete"}
                >
                  Delete card
                </FieldButton>
              </div>
            )}
            {archivedListID && (
              <div className={"boardmodalCard__buttontray"}>
                {closeModalFooterButton}
                <button
                  onClick={onUndoClick}
                  className={
                    "field__button field__button--border field__button--right"
                  }
                >
                  Restore card
                </button>
              </div>
            )}

            {isNew && (
              <div className={"boardmodalCard__buttontray"}>
                <button
                  onClick={onSaveClick}
                  className={
                    "field__button field__button--success field__button--right"
                  }
                >
                  Save
                </button>

                <button onClick={onCancelClick} className="field__button ">
                  Cancel
                </button>
              </div>
            )}
          </div>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default BoardModalCard;
