import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import AppContext, { useModalState } from "./AppState";
import BoardContext, {
  useBoardState,
  BoardStateProps,
  TemplateNameType
} from "./Board/BoardState";
import ToolBar from "./Toolbar/ToolBar.component";
import Home from "./Routes/Home";
import Summary from "./Routes/Summary";
import Modals from "./Modal/Modals.component";

export type AppProps = {
  template?: TemplateNameType | undefined;
  defaultBoardState?: BoardStateProps;
};

const App = ({ defaultBoardState, template }: AppProps) => {
  const modals = useModalState();
  const boardState = useBoardState(
    defaultBoardState || ({} as BoardStateProps)
  );

  const {
    onSetEvaluationTitle,
    evaluationTitle,
    evaluationDescription,
    onSetEvaluationDescription,
    onSetFilter,
    filter,
    lists
  } = boardState;

  return (
    <div className="App">
      <AppContext.Provider value={{ modals }}>
        <BoardContext.Provider value={boardState}>
          <Router>
            <Modals />
            <ToolBar
              onSetEvaluationTitle={onSetEvaluationTitle}
              evaluationTitle={evaluationTitle}
              onSetEvaluationDescription={onSetEvaluationDescription}
              evaluationDescription={evaluationDescription}
              onSetFilter={onSetFilter}
              filter={filter}
              lists={lists}
            />
            <Switch>
              <Route path="/summary">
                <Summary />
              </Route>

              <Route path="/">
                <Home template={template} />
              </Route>
            </Switch>
          </Router>
        </BoardContext.Provider>
      </AppContext.Provider>
    </div>
  );
};

export default App;
