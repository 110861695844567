import React, { useState, Fragment } from "react";
import AppContext, { ModalOpenType } from "../AppState";
import Card from "../Board/BoardCard/BoardCardView.modal.component";
import ModalContainer from "./ModalContainer.component";
import FilterModal from "../Filter/FilterView.modal.component";
import EvaluationModal from "../Evaluation/EvaluationView.modal.component";
import UpdateListsView from "../List/UpdateListsView.modal.component";
import HelpView from "../Help/HelpView.modal.component";
import DownloadView from "../Download/DownloadView.modal.component";
import UploadView from "../Upload/Upload.modal.component";

const Modals = () => {
  const [canClose, setCanClose] = useState(true);
  return (
    <AppContext.Consumer>
      {(value) => {
        const modalOpen: ModalOpenType = value.modals.modalOpen;
        const type: string =
          modalOpen !== "Closed" && modalOpen.type ? modalOpen.type : "";
        const targetId: string =
          modalOpen &&
          modalOpen !== "Closed" &&
          (modalOpen.type === "CreateCard" ||
            modalOpen.type === "ViewOrEditCard") &&
          modalOpen.targetId
            ? modalOpen.targetId
            : "";

        const targetIndicatorID =
          modalOpen &&
          modalOpen !== "Closed" &&
          modalOpen.type === "ViewOrEditCard" &&
          modalOpen.indicatorID
            ? modalOpen.indicatorID
            : undefined;
        const targetListID: string =
          modalOpen &&
          modalOpen !== "Closed" &&
          modalOpen.type === "CreateCard" &&
          modalOpen.targetListID
            ? modalOpen.targetListID
            : "";
        const updateListID: string =
          modalOpen &&
          modalOpen !== "Closed" &&
          modalOpen.type === "UpdateLists" &&
          modalOpen.updateListID
            ? modalOpen.updateListID
            : "";

        const disableClose = modalOpen && type === "CreateCard";

        const closeModal = () => {
          const { closeModal: close } = value.modals;
          if (canClose) {
            close();
          }
        };

        return (
          <Fragment>
            <ModalContainer
              contentLabel="Card"
              isOpen={type === "ViewOrEditCard"}
              closeModal={closeModal}
            >
              <Card
                closeModal={closeModal}
                targetListID={targetListID}
                taskID={targetId}
                indicatorID={targetIndicatorID}
                setCanClose={setCanClose}
              />
            </ModalContainer>
            <ModalContainer
              contentLabel="Add Card"
              isOpen={type === "CreateCard"}
              closeModal={closeModal}
              disableClose={disableClose}
            >
              <Card
                targetListID={targetListID}
                closeModal={closeModal}
                isNew={true}
                taskID={targetId}
                setCanClose={setCanClose}
              />
            </ModalContainer>

            <ModalContainer
              contentLabel="Evaluation details"
              isOpen={type === "EditBoardTitle"}
              closeModal={closeModal}
            >
              <EvaluationModal />
            </ModalContainer>
            <ModalContainer
              contentLabel="Filters"
              isOpen={type === "EditFilters"}
              closeModal={closeModal}
            >
              <FilterModal />
            </ModalContainer>
            <ModalContainer
              contentLabel="Update Lists"
              isOpen={type === "UpdateLists"}
              closeModal={closeModal}
            >
              <UpdateListsView preExpanded={[updateListID]} />
            </ModalContainer>
            <ModalContainer
              contentLabel="Download"
              isOpen={type === "Download"}
              closeModal={closeModal}
            >
              <DownloadView />
            </ModalContainer>
            <ModalContainer
              contentLabel="Upload"
              isOpen={type === "Upload"}
              closeModal={closeModal}
            >
              <UploadView />
            </ModalContainer>
            <ModalContainer
              contentLabel="Help"
              isOpen={type === "Help"}
              closeModal={closeModal}
            >
              <HelpView />
            </ModalContainer>
          </Fragment>
        );
      }}
    </AppContext.Consumer>
  );
};
export default Modals;
