import React, { useState, useEffect, Fragment } from "react";
import ColorPicker from "react-simple-colorpicker";
import rgbHex from "rgb-hex";
import { idGen } from "../utils";
import "./FieldInputColour.scss";
import "./ColorPicker.scss";
import Field from "../Field/Field";

type Props = {
  label: React.ReactNode;
  value: string;
  onChange: (hex: string) => void;
};

const InputColour = ({ label, value, onChange }: Props) => {
  const [id] = useState(idGen());

  useEffect(() => {
    onChange(value); // on mount set value once
  }, [value]);

  const tooltip =
    "Colour can only be chosen for a long term outcome and not for a medium or short term outcome. This logic has to do with the creation of coloured groups of outcomes related to a single long term outcome";
  return (
    <Fragment>
      <Field
        id={id}
        label="Select Card Colour"
        useCustomViewMode={false}
        value=""
        readOnly={false}
      >
        <div>
          <span>{tooltip}</span>
          <ColorPicker
            color={value}
            onChange={(rgba: string) => {
              const hex = `#${rgbHex(rgba).substr(0, 6)}`;
              onChange(hex);
            }}
          />
        </div>
      </Field>
    </Fragment>
  );
};

export default InputColour;
