import React, { useEffect, useContext, useState } from "react";
import {
  DragDropContext,
  DroppableProvided,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided
} from "react-beautiful-dnd";
import BoardList from "./BoardList/BoardList.component";
import BoardContext, {
  BoardContextType,
  TemplateNameType,
  LatestImportData
} from "./BoardState";
import "./Board.scss";
import { loadJSON, handleFileRead } from "./Board.utils";

type Props = { template?: TemplateNameType | undefined };

const Board = ({ template: templateID }: Props) => {
  const [template, setTemplate] = useState<TemplateNameType>();
  const { onImportData, isValidTemplateName } = useContext(BoardContext);

  useEffect(() => {
    // if there is a URL param, load the correct JSON and reload the app
    if (!templateID && template) {
      const isvalid = isValidTemplateName(template);
      if (isvalid) {
        loadJSON(`${template}.json`, (resultContent: string) => {
          handleFileRead({
            result: resultContent,
            onImportData: (data: LatestImportData) => {
              //set the data and templateID
              onImportData(data, template);
            }
          });
        });
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const templateParam = urlParams.get("template");
      if (templateParam) {
        setTemplate(templateParam as TemplateNameType);
      }
    }
  }, [template, isValidTemplateName, onImportData, templateID]);

  return (
    <BoardContext.Consumer>
      {({
        listsOrder,
        lists,
        indicators,
        tasks,
        onDragEnd,
        onDragListEnd,
        newTaskId,
        maxBoardListWidthPx,
        onCreateCard,
        filter
      }: BoardContextType) => {
        const hasFilters = filter ? true : false;

        const onDragEnds = (
          result: DropResult,
          provided: ResponderProvided
        ) => {
          if (result.type === "list") {
            onDragListEnd(result, provided);
          } else {
            onDragEnd(result, provided);
          }
        };

        return (
          <DragDropContext onDragEnd={onDragEnds}>
            <div className="board">
              <Droppable droppableId="boardID" type="list" direction="vertical">
                {(provided: DroppableProvided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {listsOrder.map((listId, index) => {
                      const list = lists[listId];
                      const thisTasks: TasksWithStyleType = {};
                      if (list) {
                        list.taskIds.forEach((taskId) => {
                          thisTasks[taskId] = { ...tasks[taskId] };
                        });
                      }
                      return (
                        <Draggable
                          key={listId}
                          draggableId={listId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {list ? (
                                <BoardList
                                  isViewOnly={hasFilters}
                                  onCreateCard={onCreateCard}
                                  key={list.id}
                                  listID={list.id}
                                  list={list}
                                  indicators={indicators}
                                  tasks={thisTasks}
                                  newTaskId={newTaskId}
                                  maxBoardListWidthPx={maxBoardListWidthPx}
                                  isListDragging={snapshot.isDragging}
                                />
                              ) : (
                                <span>applying filter</span>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default Board;

export type ListTitleType = string;

export type ListType = {
  id: string;
  title: ListTitleType;
  taskIds: string[];
  tip: string;
  isHighLevel?: boolean;
  type: ListCategoryType;
};

export type ListsType = {
  [key: string]: ListType;
};

export type ListCategoryType = "standard" | "outcome";
export const LIST_CATEGORIES: ListCategoryType[] = ["standard", "outcome"];

export type CreateListPropsType = {
  type: ListCategoryType;
  title: string;
  tip: string;
};

// "Active" is considered to be either
// 1) a Task in a list of 'Long Term Outcomes'; OR
// 2) a Task has a child highLevelTaskID that refers to it, even if the Task is not in the 'Long Term Outcome' list
// Tasks that don't match this criteria should be removed from this list
export type ActiveHighLevelOutcomeTaskIds = string[];

export type IndicatorsType = {
  [key: string]: IndicatorType;
};

export type IndicatorType = {
  id: string;
  title: string;
  label: string;
  description: string;
  who?: string;
  how?: string;
  howOften?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>, id: string) => void | undefined;
};

export type IndicatorMetadataType = {
  name: string;
  value: string;
};

export type TaskType = {
  id: string;
  label: string;
  description: string;
  indicators?: string[];
  highLevelTaskIDs?: string[];
};

export type TaskWithStyleType = TaskType & { cardColour?: string[] };

export type TasksType = {
  [key: string]: TaskWithStyleType;
};

export type TasksWithStyleType = {
  [key: string]: TaskWithStyleType;
};

export type ListsOrderType = string[];
