import React, { useContext } from "react";
import BoardContext, { TemplateNameType } from "../Board/BoardState";
import Board from "../Board/Board.component";

import { Printable } from "../Board/Printable";

export type HomeProps = {
  template?: TemplateNameType | undefined;
};

const Home = ({ template }: HomeProps) => {
  const { indicators, tasks, lists } = useContext(BoardContext);

  return (
    <div>
      <Board template={template} />
      <Printable indicators={indicators} lists={lists} tasks={tasks} />
    </div>
  );
};

export default Home;
