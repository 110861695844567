import { IndicatorsType, IndicatorType } from "../Board/Board.component";

const addIndicatorToListReducer = (
  oldState: IndicatorsType,
  newIndicator: IndicatorType
): IndicatorsType => {
  const newState = { ...oldState };
  newState[newIndicator.id] = { ...newIndicator };
  return newState;
};

export default addIndicatorToListReducer;
