import { BoardStateType } from "./BoardState";
import {
  ListsOrderType
} from "./Board.component";

const moveListReducer = (
  oldState: Pick<BoardStateType, "listsOrder">,
  moveListProps: MoveListPropsType
): MoveListReducerType => {
  const { listsOrder } = oldState;
  const { id, placement } = moveListProps;

  // Remove id from its current position in listsOrder, then add it at placement index
  let newListsOrder = [...listsOrder];
  newListsOrder = newListsOrder.slice().filter(item => item !== id);
  newListsOrder.splice(placement, 0, id);

  let newState = { listsOrder } as MoveListReducerType;
  return newState;
};

export default moveListReducer;

export type MoveListReducerType = {
  listsOrder: ListsOrderType;
};

export type MoveListPropsType = {
  id: string;
  placement: number;
};
