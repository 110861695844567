import { IndicatorsType } from "../Board/Board.component";

const removeIndicatorFromListReducer = (
  oldState: IndicatorsType,
  indicatorID: string
): IndicatorsType => {
  const newState = { ...oldState };

  if (newState[indicatorID]) {
    delete newState[indicatorID];

    return newState;
  }
  return oldState;
};

export default removeIndicatorFromListReducer;
