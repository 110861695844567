import { TaskType } from "./Board.component";

const removeIndicatorReducer = (
  oldState: Pick<TaskType, "indicators">,
  indicatorID: string
): Pick<TaskType, "indicators"> => {
  const ids = oldState.indicators ? [...oldState.indicators] : [];

  const indicatorIndex = ids.indexOf(indicatorID);

  if (indicatorIndex !== -1) {
    ids.splice(indicatorIndex, 1);
    return { ...oldState, indicators: ids };
  }

  return oldState;
};

export default removeIndicatorReducer;
