import { TaskType, TaskWithStyleType } from "./Board.component";

const updateCardReducer = (
  taskToBeUpdated: TaskType,
  fieldsToBeUpdated: Partial<TaskWithStyleType>
): TaskType => {
  const updatedTask = {
    ...taskToBeUpdated,
    ...fieldsToBeUpdated
  } as TaskType;

  return updatedTask;
};

export default updateCardReducer;
