import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import "./sass/index.scss";
import "./shame.scss";
import App, { AppProps } from "./App";
import { fixHeadingOnXAxis } from "./NonReact";

const root: HTMLElement | null = document.getElementById("root");

if (root) {
  ReactDOM.render(<App />, root, afterFirstRender);
}

export const resetApp = (args: AppProps) => {
  //remove previous App and it's state
  if (root) {
    ReactDOM.unmountComponentAtNode(root);
  }
  //mount an App with a default state
  ReactDOM.render(<App {...args} />, root, afterFirstRender);
};

function afterFirstRender() {
  fixHeadingOnXAxis();
}

window.addEventListener("beforeunload", function (e) {
  e.preventDefault();
  e.returnValue = "";
});
