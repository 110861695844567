import React from "react";
import AppContext from "../AppState";
import "./ToolBarButton.scss";

const ToolBarButtonFilter = () => {
  return (
    <AppContext.Consumer>
      {value => {
        const openModal = () => {
          if (!value.modals.setModalOpen) return;
          value.modals.setModalOpen({
            type: "EditFilters"
          });
        };
        return (
          <button
            className="toolbar__button"
            onClick={openModal}
            data-test-button-edit-filters
          >
            Filters
          </button>
        );
      }}
    </AppContext.Consumer>
  );
};

export default ToolBarButtonFilter;
