import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import ButtonSave from "./ToolBarButtonSave.component";
import ButtonImport from "./ToolBarButtonImport.component";
import ButtonFilter from "./ToolBarButtonFilter.component";
import ButtonEdit from "./ToolBarButtonEdit.component";
import ButtonPrint from "./ToolBarButtonPrint.component";
import MoeIcon from "./moe-icon.png";
import "./ToolBar.scss";
import BoardContext, {
  BoardStateType,
  TemplateNameType
} from "../Board/BoardState";
import { loadJSON, handleFileRead, tooltipStyle } from "../Board/Board.utils";
import UpdateListsButton from "./ToolBarButtonUpdateLists.component";
import ToolBarButtonHelp from "./ToolBarButtonHelp.component";
import { Link } from "react-router-dom";
import ToolBarButtonRoute from "./ToolBarButtonRoute.component";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuLink
} from "@reach/menu-button";
import { useAppContext } from "../AppState";
import Tooltip from "@reach/tooltip";

type Props = Pick<
  BoardStateType,
  | "evaluationTitle"
  | "onSetEvaluationTitle"
  | "evaluationDescription"
  | "onSetEvaluationDescription"
  | "filter"
  | "onSetFilter"
  | "lists"
>;

const ToolBar = ({
  evaluationTitle,
  evaluationDescription,
  filter,
  onSetFilter,
  lists
}: Props) => {
  const hasFilterApplied = filter ? true : false;
  const title = evaluationTitle ? evaluationTitle : <i>No title</i>;
  const isSummaryView = useLocation().pathname.indexOf("summary") > -1;
  const { onImportData, isValidTemplateName, isEmptyBoard } =
    useContext(BoardContext);
  const { modals } = useAppContext();
  const openTemplate = (id: TemplateNameType) => {
    //if empty, just load
    const valid = isValidTemplateName(id);
    if (valid) {
      if (isEmptyBoard()) {
        loadJSON(` ${id}.json`, (resultContent: string) => {
          handleFileRead({ result: resultContent, onImportData });
        });
      } else {
        //if there is data in the board, open a new window
        const url = "/";
        window.open(`${url}?template=${id}`, "_blank");
      }
    }
  };

  const newTemplates: { label: string; file: TemplateNameType }[] = [
    { label: "Ministry template", file: "ministry_template" }
  ];
  const demoTemplates: { label: string; file: TemplateNameType }[] = [
    {
      label: "Principal Recruitment Allowance Theory of Change",
      file: "demodata"
    },
    // {
    //   label: "Period Products Initiative Theory of Change",
    //   file: "period-products-initiative-theory-of-change"
    // },
    { label: "Covid19 Distance Learning", file: "covid19_distance_learning" },
    { label: "Reading Recovery", file: "reading_recovery" }
  ];

  const homeURL = [
    window.location.protocol,
    "//",
    window.location.host,
    window.location.pathname
  ].join("");
  return (
    <div className="toolbar">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <span>
        <span className="toolbar__moeicon">
          <img
            src={MoeIcon}
            alt="Ministry of Education | Te Tāhuhu O Te Mātauranga"
            title="Ministry of Education | Te Tāhuhu O Te Mātauranga"
            width="44"
          />
        </span>
      </span>

      <ButtonEdit title={evaluationTitle} />

      <div className="toolbar__last">
        <ToolBarButtonRoute
          to={isSummaryView ? "/" : "summary"}
          label={isSummaryView ? "Close Summary" : "View Summary"}
          isWarning={isSummaryView}
        />
        {!isSummaryView && (
          <>
            <Menu>
              {/* <Tooltip label={"list.tip"} style={tooltipStyle}> */}
              <MenuButton
                className="toolbar__button"
                title="Upload/Download or Print."
              >
                <span>File</span>
              </MenuButton>
              {/* </Tooltip> */}
              <MenuList>
                {!isSummaryView && (
                  <MenuItem
                    onSelect={() => {
                      if (!modals.setModalOpen) return;
                      modals.setModalOpen({
                        type: "Upload"
                      });
                    }}
                    className="toolbar__button toolbar__button--dropdown"
                    title="Do you want to edit your previous work? Use this option to upload your .json file."
                  >
                    Import JSON
                  </MenuItem>
                )}
                <MenuItem
                  onSelect={() => {
                    if (!modals.setModalOpen) return;
                    modals.setModalOpen({
                      type: "Download"
                    });
                  }}
                  className="toolbar__button toolbar__button--dropdown"
                  title="Use this option to save a copy of your work. Please note, you will have to upload the file to this website to edit your work (the .json file cannot be opened otherwise)."
                >
                  Download JSON
                </MenuItem>

                <MenuItem
                  onSelect={() => {
                    window.print();
                  }}
                  className="toolbar__button toolbar__button--dropdown"
                  title="Use this option to print or save as pdf. We recommend that you also download the .json file in case you want to make further edits."
                >
                  Print
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton className="toolbar__button">
                <span>New</span>
              </MenuButton>

              <MenuList>
                <a
                  target="_blank"
                  href={homeURL}
                  className="toolbar__button toolbar__button--dropdown"
                >
                  Blank
                </a>

                {newTemplates.map((template) => {
                  return (
                    <MenuItem
                      key={template.file}
                      onSelect={() => openTemplate(template.file)}
                      className="toolbar__button toolbar__button--dropdown"
                    >
                      {template.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton className="toolbar__button">
                <span>Demo</span>
              </MenuButton>
              <MenuList>
                {demoTemplates.map((template) => {
                  return (
                    <MenuItem
                      key={template.file}
                      onSelect={() => openTemplate(template.file)}
                      className="toolbar__button toolbar__button--dropdown"
                    >
                      {template.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </>
        )}
        {!isSummaryView && (
          <button
            onClick={() => {
              if (!modals.setModalOpen) return;
              modals.setModalOpen({
                type: "UpdateLists"
              });
            }}
            className="toolbar__button toolbar__button--dropdown"
          >
            Update model
          </button>
        )}
        {isSummaryView && (
          <button
            onClick={() => {
              window.print();
            }}
            className="toolbar__button toolbar__button--dropdown"
            title="Use this option to print or save as pdf. We recommend that you also download the .json file in case you want to make further edits."
          >
            Print
          </button>
        )}
        {hasFilterApplied ? (
          <button
            className="toolbar__button toolbar__button--warning"
            onClick={() => onSetFilter(undefined)}
            data-test-button-clear-filters
          >
            Clear filters
          </button>
        ) : (
          <ButtonFilter />
        )}

        <ToolBarButtonHelp />
      </div>
    </div>
  );
};

export default ToolBar;
