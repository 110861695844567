import React from "react";
import classnames from "classnames";
import "./ToolBarButton.scss";
import { Link } from "react-router-dom";

type Props = {
  to: string;
  label: string;
  isWarning?: Boolean;
};

const ToolBarButtonRoute = ({ to, label, isWarning }: Props) => {
  const classes = classnames("toolbar__button", {
    "toolbar__button--warning": isWarning
  });
  return (
    <Link className={classes} to={to}>
      {label}
    </Link>
  );
};

export default ToolBarButtonRoute;
