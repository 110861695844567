import React, { useState, ChangeEventHandler } from "react";
import { idGen } from "../utils";
import Field from "./Field";
import "./FieldSelect.scss";

type Props = {
  label: React.ReactNode;
  children: React.ReactNode;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

const Select = ({ label, value, onChange, children }: Props) => {
  const [id] = useState(idGen());

  return (
    <Field id={id} label={label} value={value} readOnly={false} useCustomViewMode={false}>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className="field__select"
      >
        {children}
      </select>
    </Field>
  );
};

export default Select;
