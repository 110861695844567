import React from "react";
import { ListType, TaskWithStyleType } from "../Board/Board.component";
import { createLinkageStyle } from "../Board/Board.utils";

export type SummaryHorizontalListProps = {
  list: ListType;
  tasks: TaskWithStyleType[];
};

const SummaryHorizontalList = ({ list, tasks }: SummaryHorizontalListProps) => {
  return (
    <div className={`row`}>
      <div className="col-xs-12">
        <div className="summary-list">
          <div className="summary-list__title gutters">{list.title}</div>
          <div className="row">
            {tasks.map((task) => {
              const borderStyle = createLinkageStyle(task, list);
              return (
                <div key={task.id} className="col-xs">
                  <div className="summary-list__cards gutters">
                    <div
                      className="summary-list__card gutters"
                      style={{
                        ...borderStyle
                      }}
                    >
                      {task.label}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryHorizontalList;
