import React from "react";

const HelpView = () => {
  return (
    <div className="filtermodalcard">
      <h2 className="modal__heading">Help</h2>
      <p>
        The Logic Model web application is a tool that will help you flesh out
        in detail the short, medium and long term outcomes that your programme,
        project or initiative intend to deliver. It will also help you visualise
        how the activities you will deliver will help achieve the outcomes you
        set out to achieve.
      </p>
      <p>
        If you need help with using the logic model app, contact the Analytics
        and Insights team, Ministry of Education at{" "}
        <a href="mailto: info.evaluationhub@education.govt.nz">
          info.evaluationhub@education.govt.nz.
        </a>
      </p>
      <p>
        Visit the{" "}
        <a target="_blank" href="https://www.evaluationhub.education.govt.nz/">
          Evaluation Hub
        </a>{" "}
        website to learn more about this topic
      </p>
      Here is the{" "}
      <a href="/Logic.App.Manual.FINAL.24.02.21.pdf" target="_blank">
        Logic Model App Manual
      </a>
      .
    </div>
  );
};

export default HelpView;
