import React, { useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import BoardContext from "../Board/BoardState";
import "./Upload.scss";
import { useAppContext } from "../AppState";
import FieldButton from "../Field/FieldButton";
import { handleFileRead } from "../Board/Board.utils";

const UploadView = () => {
  const { modals } = useAppContext();
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [currentError, setCurrentError] = useState<string | undefined>(
    undefined
  );
  const fileInputRef = useRef<any>(null);
  const onCloseClick = () => {
    modals.closeModal();
  };

  const onTargetClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };
  const onGetData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      !event ||
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    )
      return;
    const file = event.target.files[0];
    if (file) {
      if (file.name.endsWith(".json")) {
        setCurrentError(undefined);
        setCurrentFile(file);
      } else {
        setCurrentError("Please select a .json file");
        setCurrentFile(undefined);
      }
    }
  };
  return (
    <BoardContext.Consumer>
      {({ onImportData }) => {
        let fileReader: FileReader;
        const onUpload = () => {
          if (!currentFile) return;
          const file = currentFile;
          console.log({ file });
          fileReader = new FileReader();
          fileReader.onloadend = () => {
            handleFileRead({
              result: fileReader.result,
              onImportData
            });
          };
          if (file instanceof Blob) fileReader.readAsText(file);
          else {
            throw Error(`Invalid file. (code: ${typeof file})`);
          }
        };

        const disableUploadButton = !currentFile;
        console.log({ disableUploadButton });

        return (
          <div className="filtermodalcard">
            <h2 className="modal__heading">Upload</h2>

            <FileDrop
              onTargetClick={onTargetClick}
              onDrop={(files: any, event: any) => {
                if (!files) return;

                const jsonFile = [...files].filter((file: any) =>
                  file.name.endsWith(".json")
                )[0];
                if (jsonFile) {
                  setCurrentError(undefined);
                  setCurrentFile(jsonFile);
                } else {
                  setCurrentError("Please select a .json file");
                  setCurrentFile(undefined);
                }
              }}
            >
              <div className="upload-message">
                <p>
                  Drag and drop your .json file here
                  <br />
                  or
                </p>
                <span className="upload-message__fake-button">
                  Browse files
                </span>
              </div>
            </FileDrop>

            <input
              ref={fileInputRef}
              onChange={(e) => {
                if (onGetData) onGetData(e);
              }}
              type="file"
              className="hidden"
            />
            {currentFile && (
              <div className="upload-message__files">
                <p className="upload-message__file-name">
                  Selected file: {currentFile.name}
                  {` (${Math.round(currentFile.size / 1000)}Kb)`}
                </p>
                <div className="upload-message__file-delete">
                  <FieldButton
                    icon="delete"
                    onClick={() => {
                      setCurrentFile(undefined);
                    }}
                  >
                    Delete
                  </FieldButton>
                </div>
              </div>
            )}
            {currentError && (
              <p className="upload-message__file-error">{currentError}</p>
            )}
            <div className="buttons-tray">
              <div className="buttons-tray__cancel">
                <FieldButton onClick={onCloseClick}>Cancel</FieldButton>
              </div>
              <div className="buttons-tray__upload">
                <FieldButton
                  level="confirm"
                  disabled={disableUploadButton}
                  onClick={onUpload}
                >
                  Upload
                </FieldButton>
              </div>
            </div>
          </div>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default UploadView;
