import React, { useState, useCallback, ReactChild, Fragment } from "react";
import { convertToHTML } from "../utils";
import "./Field.scss";
import classNames from "classnames";

type FieldBaseProps = {
  id: string;
  label: React.ReactNode;
  children: ReactChild;
  readModeClassName?: string | undefined;
  placeholder?: string | null;
  isTextArea?: boolean | undefined;
};

type FieldToggleEditProps = FieldBaseProps & {
  useCustomViewMode: boolean | true;
  readOnly: false;
  forceEditMode?: boolean | undefined;
  value: string | undefined;
};

type FieldReadOnlyProps = FieldBaseProps & {
  readOnly: true;
};

const Field = (props: FieldToggleEditProps | FieldReadOnlyProps) => {
  const {
    isTextArea,
    id,
    label,
    children,
    readOnly,
    readModeClassName,
    placeholder
  } = props;
  const value = props.readOnly ? "" : props.value;
  let useCustomViewMode = true;
  let forcedEditMode = false;

  if (!props.readOnly) {
    useCustomViewMode = props.useCustomViewMode;
    forcedEditMode = !!props.forceEditMode;
  }
  const [isEditing, setIsEditing] = useState(forcedEditMode);
  const [selectionPosition, setSelectionPosition] = useState(0);

  const showCustomViewMode = !isEditing && useCustomViewMode;
  return (
    <div className="field">
      <label htmlFor={id} className="field__label">
        {label}
      </label>
      {showCustomViewMode ? (
        <ReadMode
          isTextArea={isTextArea}
          readOnly={readOnly}
          value={value}
          placeholder={placeholder}
          setIsEditing={setIsEditing}
          setSelectionPosition={setSelectionPosition}
          className={readModeClassName}
        />
      ) : (
        <EditMode
          children={children}
          selectionPosition={selectionPosition}
          setIsEditing={setIsEditing}
        />
      )}
    </div>
  );
};

type ReadModeProps = {
  value: string | undefined;
  placeholder?: string | null;
  className: string | undefined;
  setIsEditing: Function;
  setSelectionPosition: Function;
  readOnly?: boolean;
  isTextArea: boolean | undefined;
};

function ReadMode({
  readOnly,
  value,
  setIsEditing,
  placeholder,
  className,
  isTextArea,
  setSelectionPosition
}: ReadModeProps) {
  const dangerousHTML = convertToHTML(value);
  const getTextLengthBetweenElements = (
    element: Node,
    lastElement: Node | null,
    countOffset: number
  ) => {
    let counter = countOffset || 0;
    let whiteSpaceFound;
    if (!lastElement) return counter;

    const children = element.childNodes;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (lastElement === node) {
        if (whiteSpaceFound) counter -= 1;
        return counter;
      }
      const isTextNode = node.nodeName === "#text";
      if (isTextNode) {
        if (node.nodeValue) {
          //remove whitespaces at the end and start
          counter += node.nodeValue.trim().length || 0;
        }
      } else {
        //breaks are /n so add 1
        if (node.nodeName === "BR") {
          whiteSpaceFound = true;
          counter += 1;
        } else {
          counter += getTextLengthBetweenElements(node, lastElement, 0);
        }
      }
    }
    return counter;
  };

  const onClick = useCallback(
    (e: any) => {
      const selection = window.getSelection();

      if (selection) {
        const mainElement = e.target;

        const anchorNode = selection.anchorNode;

        const position = selection.focusOffset;
        let charsFromStart = getTextLengthBetweenElements(
          mainElement,
          anchorNode,
          position
        );

        setSelectionPosition(charsFromStart);
      }

      if (readOnly) return;
      const clickedElement = e.target;
      const isLink = clickedElement.classList.contains("u-generated-link");
      // not clicked on a link, go in edit mode
      if (!isLink) {
        setIsEditing(true);
      }
    },
    [readOnly, setIsEditing]
  );
  const onFocus = useCallback(
    (e: any) => {
      if (readOnly) return;
      setSelectionPosition(0);
      setIsEditing(true);
    },
    [readOnly, setIsEditing]
  );

  return (
    <Fragment>
      <input type="text" onFocus={onFocus} className="visuallyhidden" />
      <div
        role="button"
        tabIndex={0}
        className={classNames(["field--readonly", className])}
        onClick={onClick}
      >
        {value ? (
          <span
            className="field__editable field-padding"
            dangerouslySetInnerHTML={{ __html: dangerousHTML }}
          ></span>
        ) : isTextArea ? (
          <textarea
            className="field__editable field__editable--textarea field-padding"
            placeholder={placeholder || undefined}
          ></textarea>
        ) : (
          <input
            className="field__editable field__editable--textarea field-padding"
            placeholder={placeholder || undefined}
          ></input>
        )}
      </div>
      <input
        type="text"
        style={{ border: 0 }}
        onFocus={onFocus}
        className="visuallyhidden"
      />
    </Fragment>
  );
}

type EditModeProps = {
  children: ReactChild;
  setIsEditing: Function;
  selectionPosition: number;
};

export const FieldContext = React.createContext({
  selectionPosition: 0,
  onBlur: () => {}
});
export type FieldContextType = {
  onBlur: (event: React.FocusEvent<HTMLElement>) => void;
  selectionPosition: number;
};

function EditMode({
  children,
  setIsEditing,
  selectionPosition
}: EditModeProps) {
  return (
    <FieldContext.Provider
      value={{
        selectionPosition: selectionPosition || 0,
        onBlur: useCallback(() => {
          setIsEditing(false);
        }, [setIsEditing])
      }}
    >
      <span
        style={{
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: " 1px",
          width: " 1px",
          margin: "-1px",
          padding: " 0",
          border: " 0"
        }}
      >
        Click to edit the following content:
      </span>
      {children}
    </FieldContext.Provider>
  );
}

export default Field;
