type LookupType = { [fieldId: string]: string };

export const GLOSS = {
  CARD_INPUT_PLACEHOLDER: "CARD_INPUT",
  CARD_INPUT_PLACEHOLDER_ASSUMPTIONS: "CARD_INPUT_PLACEHOLDER_ASSUMPTIONS",
  CARD_INPUT_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST:
    "CARD_INPUT_PLACEHOLDER_INPUT",
  CARD_INPUT_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES:
    "CARD_INPUT_PLACEHOLDER_NEW_OUTPUT",
  CARD_INPUT_PLACEHOLDER_SHORT_TERM_OUTCOME:
    "CARD_INPUT_PLACEHOLDER_SHORT_TERM_OUTCOME",
  CARD_INPUT_PLACEHOLDER_MEDIUM_TERM_OUTCOME:
    "CARD_INPUT_PLACEHOLDER_MEDIUM_TERM_OUTCOME",
  CARD_INPUT_PLACEHOLDER_LONG_TERM_OUTCOME:
    "CARD_INPUT_PLACEHOLDER_LONG_TERM_OUTCOME",

  CARD_DESCRIPTION_PLACEHOLDER: "CARD_DESCRIPTION_INPUT",
  CARD_DESCRIPTION_PLACEHOLDER_ASSUMPTIONS:
    "CARD_DESCRIPTION_PLACEHOLDER_ASSUMPTIONS",
  CARD_DESCRIPTION_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST:
    "CARD_DESCRIPTION_PLACEHOLDER_INPUT",
  CARD_DESCRIPTION_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES:
    "CARD_DESCRIPTION_PLACEHOLDER_NEW_OUTPUT",
  CARD_DESCRIPTION_PLACEHOLDER_SHORT_TERM_OUTCOME:
    "CARD_DESCRIPTION_PLACEHOLDER_SHORT_TERM_OUTCOME",
  CARD_DESCRIPTION_PLACEHOLDER_MEDIUM_TERM_OUTCOME:
    "CARD_DESCRIPTION_PLACEHOLDER_MEDIUM_TERM_OUTCOME",
  CARD_DESCRIPTION_PLACEHOLDER_LONG_TERM_OUTCOME:
    "CARD_DESCRIPTION_PLACEHOLDER_LONG_TERM_OUTCOME",

  CARD_INDICATOR_LABEL_PLACEHOLDER: "CARD_INDICATOR_LABEL_PLACEHOLDER",
  CARD_INDICATOR_TITLE_PLACEHOLDER: "CARD_INDICATOR_TITLE_PLACEHOLDER",
  CARD_INDICATOR_DESCRIPTION_PLACEHOLDER:
    "CARD_INDICATOR_DESCRIPTION_PLACEHOLDER",
  CARD_INDICATOR_WHO_COLLECTS_PLACEHOLDER:
    "CARD_INDICATOR_WHO_COLLECTS_PLACEHOLDER",
  CARD_INDICATOR_HOW_TO_COLLECT_PLACEHOLDER:
    "CARD_INDICATOR_HOW_TO_COLLECT_PLACEHOLDER",
  CARD_INDICATOR_HOW_OFTEN_TO_COLLECT_PLACEHOLDER:
    "CARD_INDICATOR_HOW_OFTEN_TO_COLLECT_PLACEHOLDER",

  LIST_UPDATE_TITLE_PLACEHOLDER: "LIST_UPDATE_TITLE_PLACEHOLDER",
  LIST_UPDATE_TIP_PLACEHOLDER: "LIST_UPDATE_TIP_PLACEHOLDER"
};

const glossaryLookup = {} as LookupType;

glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER] =
  "The title will be displayed on the card";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_ASSUMPTIONS] =
  "Allocation of this resources across regions is informed by the equity index. This includes investing in Trainers, Tutors, and RR teachers.";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST] =
  "$50k annual allowance";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES] =
  "Principals are recruited";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_SHORT_TERM_OUTCOME] =
  "Quality leadership is initiated with community contribution";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_MEDIUM_TERM_OUTCOME] =
  "Quality leadership has positive impact on teaching practice";
glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_LONG_TERM_OUTCOME] =
  "Distributed leadership is embedded";

glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER] = "Description";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_ASSUMPTIONS] =
  "Assumptions are the conditions that are necessary for success, and you believe are true. Your programme needs these conditions in order to succeed, but you believe these conditions already exist – they are not something you bring about with your programme activities.";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_WHAT_RESOURCES_WE_INVEST] =
  "In evaluation, we call this ‘Inputs’. It refers to the resources we invest in the programme, policy or initiative. The resources include human, financial, organisational, and community resources available for carrying out a programme's activities.";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_WHAT_WE_DO_WITH_RESOURCES] =
  "This is also known as Outputs and/or Activities.  Outputs are what we do with the resources we provide. These are the widgets that we produce or activities we implement. Activities are the processes, tools, events and actions that are used to bring about a programme’s itended changes or results. Inputs and activities can be lumped together but you can also create a separate Activities row.";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_SHORT_TERM_OUTCOME] =
  "Short-Term Outcomes refer to changes in awareness, knowledge, attitudes, skills, opinions, aspirations and motivation. They are measured at the end of the project, programme or initiative. They can be directly tied to the intervention.";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_MEDIUM_TERM_OUTCOME] =
  "Medium-Term Outcomes refer to refers to changes in behaviour, practice, decision-making, policies and social action. Depending on the complexity of the program. Medium-Term Outcomes are usually measured within 2-3 years of implementation.";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER] = "Add your description";
glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_LONG_TERM_OUTCOME] =
  "Long-Term Outcomes refer to changes in economic, social, civic, environmental conditions. They are measured several years after programme completion. They are hard to directly connect to the intervention. These levels of outcomes are related and built on the short and medium term outcomes. ";

// glossaryLookup[GLOSS.CARD_INPUT_PLACEHOLDER_SECONDARY] =
//   "this will show on the card ";
// glossaryLookup[GLOSS.CARD_DESCRIPTION_PLACEHOLDER_SECONDARY] =
//   "this is the description";

glossaryLookup[GLOSS.CARD_INDICATOR_LABEL_PLACEHOLDER] =
  "this is the label description";
glossaryLookup[GLOSS.CARD_INDICATOR_TITLE_PLACEHOLDER] =
  "this is the title description";
glossaryLookup[GLOSS.CARD_INDICATOR_DESCRIPTION_PLACEHOLDER] =
  "this is the indicator description";
glossaryLookup[GLOSS.CARD_INDICATOR_WHO_COLLECTS_PLACEHOLDER] =
  "this is the who description";
glossaryLookup[GLOSS.CARD_INDICATOR_HOW_TO_COLLECT_PLACEHOLDER] =
  "this is the how description";
glossaryLookup[GLOSS.CARD_INDICATOR_HOW_OFTEN_TO_COLLECT_PLACEHOLDER] =
  "this is the how often description";

glossaryLookup[GLOSS.LIST_UPDATE_TITLE_PLACEHOLDER] =
  "this is the title of the list";
glossaryLookup[GLOSS.LIST_UPDATE_TIP_PLACEHOLDER] =
  "this is the tip on roll over of the list";

export const getGloss = (id: string): string | null => {
  return glossaryLookup[id];
};
