import { ListType, ListsType, TaskType } from "./Board.component";
import { moveToList } from "./Board.DragEnd.reducer";
import { getListDetailsFromTask } from "./Board.utils";

const moveTaskToListReducer = (
  lists: ListsType,
  task: TaskType,
  targetListID: string
): ListsType => {
  const { listID, index } = getListDetailsFromTask(task.id, lists);
  if (listID) {
    const startList: ListType = lists[listID];
    const finishList: ListType = lists[targetListID];
    const isSameList = startList === finishList;

    if (isSameList) {
      return lists;
    }

    // Moving
    return moveToList(
      startList,
      index,
      finishList,
      finishList.taskIds.length,
      lists
    );
  } else {
    // card is not yet part of list
    const defaultList: ListType = lists[targetListID];
    const updatedTasks = defaultList.taskIds
      ? [task.id, ...defaultList.taskIds]
      : [task.id];

    defaultList.taskIds = updatedTasks;

    return {
      ...lists,
      [defaultList.id]: { ...defaultList }
    } as ListsType;
  }
};

export default moveTaskToListReducer;
