import React from "react";
import {
  IndicatorsType,
  TasksType,
  ListsType,
  ListTitleType
} from "./Board.component";
import "./Printable.scss";

type Props = {
  indicators: IndicatorsType;
  tasks: TasksType;
  lists: ListsType;
};

export const Printable = ({ indicators, tasks, lists }: Props) => {
  const usedIndicators = [] as string[];
  const listOrder: ListTitleType[] = [
    "Long Term Outcome",
    "Medium Term Outcome",
    "Short Term Outcome",
    "Output",
    "Input",
    "Assumptions"
  ];
  const sortedLists = Object.values(lists);

  sortedLists.sort((a, b) => {
    return listOrder.indexOf(a.title) - listOrder.indexOf(b.title);
  });

  const hasIndicatorsInList = (taskIds: string[]) => {
    const withIndicators = [];
    taskIds.forEach(id => {
      const task = tasks[id];
      const indicatorListTask = task.indicators || [];
      indicatorListTask.forEach((id: string) => withIndicators.push(id));
    });
    return withIndicators.length > 0;
  };

  const print = sortedLists.map(({ title, taskIds, id }) => {
    const hasTasksWithIndicators = hasIndicatorsInList(taskIds);
    if (!hasTasksWithIndicators) return null;
    const titleAbreviation = title
      .split(" ")
      .reduce((accum, word) => {
        return (accum += word.charAt(0));
      }, " ")
      .toUpperCase();
    return (
      <div key={id}>
        <h1>
          {title} - {titleAbreviation}
        </h1>

        {taskIds.map(id => {
          const task = tasks[id];
          const indicatorListTask = task.indicators || [];
          indicatorListTask.forEach((id: string) => usedIndicators.push(id));

          const taskLayout = (
            <div key={task.id} className="printable">
              <h2>{task.label}</h2>
              <div className="printable__label">Indicators:</div>
              {indicatorListTask.map(key => {
                const {
                  title = "",
                  label = "",
                  description = "",
                  how = "",
                  howOften = "",
                  who = ""
                } = indicators[key] || {};
                return (
                  <div key={key} className="printable__indicator">
                    <h2 className="printable__text">{`${label} - ${titleAbreviation}`}</h2>
                    <div className="printable__label">{`Title:`}</div>
                    <div className="printable__text">{`${title}`}</div>
                    <div className="printable__label">{`Description:`}</div>
                    <div className="printable__text">{` ${description}`}</div>
                    {how && (
                      <div>
                        <div className="printable__label">{`How to collect: `}</div>
                        <div className="printable__text">{`${how}`}</div>
                      </div>
                    )}
                    {howOften && (
                      <div>
                        <div className="printable__label">{`How often to collect: `}</div>
                        <div className="printable__text">{`${howOften}`}</div>
                      </div>
                    )}
                    {who && (
                      <div>
                        <div className="printable__label">{`Who is collecting: `}</div>
                        <div className="printable__text">{`${who}`}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );

          return indicatorListTask.length > 0 ? (
            taskLayout
          ) : (
            <div key={task.id} className="printable">
              <h2>{task.label}</h2>
              no indicators
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div className={"printable"}>
      <p>Indicators: </p>
      {print}
    </div>
  );
};
