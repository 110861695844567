import { uniqueId } from "lodash";
import hexRgb from "hex-rgb";

//Temporary uuid generator
export const idGen = (): string =>
  uniqueId("idGen_" + Date.now() / 1000).toUpperCase();

export function getBrightnessHex(colour: string): number {
  const rgb = hexRgb(colour);
  return getBrightness(rgb.red, rgb.green, rgb.blue);
}

export function getBrightness(r: number, g: number, b: number): number {
  return Math.sqrt(
    Math.pow(r * 0.299, 2) + Math.pow(g * 0.587, 2) + Math.pow(b * 0.114, 2)
  );
}

export const replaceLinks = (html: string = "") => {
  return html.replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "https://" + hyperlink;
      }
      const aTag = document.createElement("a");
      aTag.setAttribute("href", hyperlink);
      aTag.textContent = url;
      aTag.className = "u-generated-link";
      aTag.setAttribute("rel", "noopener noreferrer");
      aTag.setAttribute("target", "_blank");
      // Escape the url
      return " " + aTag.outerHTML;
    }
  );
};

export const replaceLineBreaks = (html: string = "") => {
  return html.replace(/(?:\r\n|\r|\n)/g, " <br> ");
};

export const convertToHTML = (html: string = "") => {
  const lineBreaks = replaceLineBreaks(html);

  return replaceLinks(lineBreaks);
};
