import { BoardStateType } from "./BoardState";
import { ListsType, ListsOrderType } from "./Board.component";

import archiveCardReducer from "./Board.ArchiveCard.reducer";

const deleteListReducer = (
  oldState: Pick<BoardStateType, "lists" | "listsOrder">,
  listID: string
): DeleteListReducerType => {
  const { lists, listsOrder } = oldState;
  let newState = {
    lists: { ...lists },
    listsOrder: [...listsOrder]
  } as DeleteListReducerType;
  // isHighLevel rows are not allowed to be removed
  // is row allowed to be removed?
  const listToBeRemoved = lists[listID];
  if (!listToBeRemoved.isHighLevel) {
    const { taskIds } = listToBeRemoved;

    taskIds.forEach((taskID) => {
      // we archive the tasks for now
      const { lists: updatedLists } = archiveCardReducer(
        { lists: newState.lists },
        taskID
      );
      // remove alll references to archived task
      newState.lists = updatedLists;
    });
    // remove from the order
    const index = newState.listsOrder.indexOf(listID);
    newState.listsOrder.splice(index, 1);

    // remove the list
    delete newState.lists[listID];
  }

  return newState;
};

export type DeleteListReducerType = {
  lists: ListsType;
  listsOrder: ListsOrderType;
};

export default deleteListReducer;
