import React from "react";
import { ListType, TaskWithStyleType } from "../Board/Board.component";
import { createLinkageStyle } from "../Board/Board.utils";

export type SummaryListProps = {
  list: ListType;
  tasks: TaskWithStyleType[];
};

const SummaryList = ({ list, tasks }: SummaryListProps) => {
  return (
    <div className={`col-xs `}>
      <div className="summary-list">
        <div className="summary-list__title gutters">{list.title}</div>
        <div className="summary-list__cards gutters">
          {tasks.map((task) => {
            const borderStyle = createLinkageStyle(task, list);

            return (
              <div
                key={task.id}
                className="summary-list__card gutters"
                style={{
                  ...borderStyle
                }}
                dangerouslySetInnerHTML={{ __html: task.label }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SummaryList;
