import React, { useState, SetStateAction, Dispatch, useContext } from "react";

export const MODAL_CLOSED = "Closed";

const DEFAULT_APP_STATE = {
  modals: {
    modalOpen: MODAL_CLOSED,
    setModalOpen: () => {},
    closeModal: () => {}
  }
} as AppStateType;

const AppContext = React.createContext<AppStateType>(DEFAULT_APP_STATE);
export default AppContext;

export const useModalState = (): ModalsType => {
  const [modalOpen, setModalOpen] = useState<ModalOpenType>(MODAL_CLOSED);
  const closeModal = React.useCallback(() => {
    if (setModalOpen) {
      setModalOpen(MODAL_CLOSED);
    }
  }, []);

  return {
    modalOpen,
    setModalOpen,
    closeModal
  };
};

export const useAppContext = (): AppStateType => useContext(AppContext);

export type ModalOpenType =
  | "Closed"
  | EditBoardModalType
  | CreateCardModalType
  | EditIndicatorsType
  | ViewEditOrDeleteModalType
  | UpdateListsModalType
  | EditFiltersType
  | DownloadModalType
  | UploadModalType
  | HelpModalType;

export type CreateCardModalType = {
  type: "CreateCard";
  targetListID: string;
  targetId: string;
};

export type ViewEditOrDeleteModalType = {
  type: "ViewOrEditCard" | "DeleteCard";
  targetId: string;
  indicatorID?: string | undefined;
};
export type EditIndicatorsType = {
  type: "EditIndicators";
  targetId: string;
};
export type EditFiltersType = {
  type: "EditFilters";
};
export type EditBoardModalType = {
  type: "EditBoardTitle";
};
export type UpdateListsModalType = {
  type: "UpdateLists";
  updateListID?: string | undefined;
};
export type DownloadModalType = {
  type: "Download";
};
export type UploadModalType = {
  type: "Upload";
};
export type HelpModalType = {
  type: "Help";
};

export type SetUseModalType = Dispatch<SetStateAction<ModalOpenType>>;

export type ModalsType = {
  modalOpen: ModalOpenType;
  setModalOpen: SetUseModalType;
  closeModal: () => void;
};

export type AppStateType = {
  modals: ModalsType;
};

export const DEFAULT_MODAL_OPEN = {
  targetId: undefined,
  type: undefined
};
