import React, { useState } from "react";
import { idGen } from "../utils";
import Field from "./Field";
import "./FieldCheckList.scss";

type Props = {
  label: string;
  items: { label: string; value: string; checked: boolean; name: string }[];

  onChange: (id: string, isChecked: boolean) => void;
};

const FieldCheckList = ({ label, items, onChange }: Props) => {
  const [id] = useState(idGen());

  const onItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value, e.target.checked);
  };

  return (
    <Field
      id={id}
      label={label}
      useCustomViewMode={false}
      value=""
      readOnly={false}
    >
      <ul className="checklist">
        {items.map(({ label, value, checked, name }) => (
          <li key={label} className="checklist__item">
            <label>
              <input
                name={name}
                type="checkbox"
                value={value}
                key={value}
                checked={checked}
                onChange={onItemChange}
              />
              {label}
            </label>
          </li>
        ))}
      </ul>
    </Field>
  );
};

export default FieldCheckList;
