import React from "react";
import { Droppable } from "react-beautiful-dnd";
import Tooltip from "@reach/tooltip";
import { ListType } from "../Board.component";
import BoardCard from "../BoardCard/BoardCard.component";
import AppContext from "../../AppState";
import classNames from "classnames";
import "./BoardList.scss";
import { BoardContextType } from "../BoardState";
import { idGen } from "../../utils";
import { tooltipStyle } from "../Board.utils";

type Props = Pick<
  BoardContextType,
  "indicators" | "newTaskId" | "maxBoardListWidthPx" | "tasks" | "onCreateCard"
> & {
  listID: string;
  list: ListType;
  isViewOnly?: boolean;
  isListDragging?: boolean;
};

const BoardList = ({
  listID,
  list,
  tasks,
  newTaskId,
  maxBoardListWidthPx,
  indicators,
  onCreateCard,
  isViewOnly,
  isListDragging
}: Props) => {
  return (
    <AppContext.Consumer>
      {(value) => {
        const onOpenModal = () => {
          if (!value.modals.setModalOpen) return;

          const newID = idGen();
          onCreateCard(
            {
              id: newID,
              label: "",
              description: ""
            },
            listID
          );

          value.modals.setModalOpen({
            type: "CreateCard",
            targetId: newID,
            targetListID: listID
          });
        };

        const onListNameClick = () => {
          value.modals.setModalOpen({
            type: "UpdateLists",
            updateListID: listID
          });
        };
        return (
          <Droppable droppableId={list.id} direction="horizontal">
            {(provided, snapshot) => {
              const { isDraggingOver } = snapshot;
              return (
                <div
                  className={classNames("boardlist", {
                    "boardlist--card-drag-over": isDraggingOver,
                    "boardlist--list-drag-over": isListDragging
                  })}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ minWidth: `${maxBoardListWidthPx}px` }}
                  id={`boardlist-${list.id}`}
                >
                  <div className="boardlist__heading-container">
                    {!list.isHighLevel && (
                      <div className="boardlist__draghandle"></div>
                    )}
                    {/* <Tooltip style={tooltipStyle}> */}
                    <button
                      className="boardlist__heading"
                      onClick={onListNameClick}
                      title={list.tip}
                    >
                      {list.title}
                    </button>
                    {/* </Tooltip> */}
                    <Tooltip label={`Add ${list.title}.`} style={tooltipStyle}>
                      <button
                        className="boardlist__add"
                        onClick={onOpenModal}
                        aria-label={`Add ${list.title}`}
                        data-test-button-create-card-modal
                      >
                        +
                      </button>
                    </Tooltip>
                  </div>

                  {Object.keys(tasks).map((key, index) => (
                    <BoardCard
                      isDragDisabled={isViewOnly}
                      key={`${tasks[key].id}${index}`}
                      isHighLevel={list.isHighLevel}
                      task={tasks[key]}
                      indicators={indicators}
                      showIndicators={list.type === "outcome"}
                      index={index}
                      isNewTask={newTaskId === tasks[key].id}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        );
      }}
    </AppContext.Consumer>
  );
};

export default BoardList;
