import React from "react";
import BoardContext, { BoardContextType } from "../Board/BoardState";

import FieldSelect from "../Field/FieldSelect";

const FilterModal = () => {
  return (
    <BoardContext.Consumer>
      {(boardValue: BoardContextType) => {
        const { unfilteredLists, filter, highLevelListID } = boardValue;
        const hasHighLevelCards =
          unfilteredLists[highLevelListID].taskIds &&
          unfilteredLists[highLevelListID].taskIds.length > 0;

        const highLevelName = unfilteredLists[highLevelListID].title;
        return (
          <div className="filtermodalcard">
            <h2 className="modal__heading">Filters</h2>

            {hasHighLevelCards ? (
              <div>
                <hr />
                <FieldSelect
                  label={`${highLevelName} reference`}
                  value={
                    filter && filter.highLevelTaskIDs
                      ? filter.highLevelTaskIDs
                      : ""
                  }
                  onChange={(event) => {
                    const value =
                      event.target.value === "reset"
                        ? undefined
                        : event.target.value;

                    const filter = value
                      ? {
                          // or highleveltaskIDs contain value
                          highLevelTaskIDs: value,
                          // or the id matches
                          id: value
                        }
                      : undefined;
                    boardValue.onSetFilter(filter);
                  }}
                >
                  <option value={"reset"} key={"default-option"}>
                    {"No Filter"}
                  </option>
                  {unfilteredLists[highLevelListID].taskIds.map(
                    (taskID: string) => (
                      <option value={taskID} key={taskID}>
                        {boardValue.tasks[taskID].label}
                      </option>
                    )
                  )}
                </FieldSelect>
                <hr />

                <button onClick={() => boardValue.onSetFilter(undefined)}>
                  Remove all filters
                </button>
              </div>
            ) : (
              <span>
                No long term outcomes to filter on.
                <br /> Please add some and try again.
              </span>
            )}
          </div>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default FilterModal;
