import React from "react";
import { kebabCase } from "lodash";
import { saveAs } from "file-saver";
import dateFns from "date-fns";
import BoardContext, { VERSION_0_3 } from "../Board/BoardState";
import "./Download.scss";
import { useAppContext } from "../AppState";
import FieldButton from "../Field/FieldButton";

const TIME_FORMAT = "DD-MM-YY-hh-mm-ss";

const DownloadView = () => {
  const { modals } = useAppContext();
  const onCloseClick = () => {
    modals.closeModal();
  };
  return (
    <BoardContext.Consumer>
      {({
        lists,
        tasks,
        listsOrder,
        indicators,
        evaluationTitle,
        evaluationDescription
      }) => {
        const now = dateFns.format(new Date(), TIME_FORMAT);
        const fileName = `${kebabCase(evaluationTitle)}-${now}.json`;
        const onExportContext = () => {
          const toBackup: string = JSON.stringify({
            readme: "Use the Learning Tool App to open this file",
            version: VERSION_0_3,
            data: {
              lists,
              tasks,
              listsOrder,
              indicators,
              evaluationTitle,
              evaluationDescription
            }
          });
          const blob = new Blob([toBackup]);
          saveAs(blob, `${fileName}`);
        };

        return (
          <div className="filtermodalcard">
            <h2 className="modal__heading">
              You are about to download a Json file to your device
            </h2>
            <p>
              Your work will be downloaded as a .json file. You can share it
              with others (i.e. attach the .json file to an email). Please note
              that you can only open the .json file via this website. If you
              want to save your work as a pdf, choose the “Print” option.
            </p>
            <p className="download-file">{fileName}</p>
            <div className="field__button--right">
              <FieldButton
                onClick={onExportContext}
                level="confirm"
                icon={"download"}
              >
                Download
              </FieldButton>
            </div>
            <FieldButton onClick={onCloseClick}>Close</FieldButton>
          </div>
        );
      }}
    </BoardContext.Consumer>
  );
};

export default DownloadView;
