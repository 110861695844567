import {
  TasksType,
  ListsType,
  ListTitleType,
  ListCategoryType
} from "./Board.component";

const filterListsReducer = (
  lists: ListsType,
  tasks: TasksType,
  filter: FilterType | undefined
): ListsType => {
  if (!filter) return lists;

  //check each task for each list

  const updatedLists = { ...lists };

  const listArray = Object.values(lists);
  listArray.forEach((list) => {
    const newList = { ...list };
    //filter the task id's that adhere to filter criteria
    const filteredTasks = list.taskIds.filter((taskID) => {
      let filterMatches;
      const task = tasks[taskID];
      //loop through all  filter settings

      //get filter setting keys and values
      Object.keys(filter).forEach((key: string) => {
        //if matches, set to true
        const castedKey = key as keyof FilterType;
        if (key in task) {
          //same key and value?
          const prop = task[castedKey];
          const filterValue = filter[castedKey];
          if (prop === filterValue) {
            filterMatches = true;
          }
          //same array value?
          if (Array.isArray(prop)) {
            //specific case: we safe highLevelTaskIds when cards get moved into the HighLevel row - this results in unwatned filter results!

            const isHighLevelTaskIDs =
              castedKey === "highLevelTaskIDs" && list.isHighLevel;
            if (
              !isHighLevelTaskIDs &&
              filterValue &&
              prop.indexOf(filterValue) > -1
            ) {
              filterMatches = true;
            }
          }
          // in the future we can check indicator ID array here by looping over it
        }
      });

      // if task isn't in an outcome List, return it as part of the filtered list

      const outcome: ListCategoryType = "outcome";
      const isOutComeList = list.type === outcome;

      if (!isOutComeList) {
        filterMatches = true;
      }

      return filterMatches;
    });
    newList.taskIds = [...filteredTasks];
    updatedLists[newList.id] = newList;
  });
  return updatedLists;
};

export type FilterType = {
  highLevelTaskIDs?: string;
  id?: string;
};

export default filterListsReducer;
