import { BoardStateType } from "./BoardState";
import {  ListsType, ListType } from "./Board.component";
import {
  removeFromList,
  updateList,
  updateCollection
} from "./Board.DragEnd.reducer";

const archiveCardReducer = (
  oldState: Pick<BoardStateType, "lists">,
  cardID: string
): ArchiveCardReducerType => {
  const { lists } = oldState;
  let newState = { lists: { ...lists } } as ArchiveCardReducerType;
  //get list that contains the id
  const allLists = Object.values(lists) as ListType[];
  allLists.forEach((list: ListType) => {
    const taskIndexInList = list.taskIds.indexOf(cardID);

    if (taskIndexInList > -1) {
      const newTasksList: string[] = removeFromList(
        list.taskIds,
        taskIndexInList
      );

      const updatedList: ListType = updateList(list, newTasksList);
      const updatedLists: ListsType = updateCollection(lists, updatedList);
      newState = { lists: updatedLists, archivedFromList: list.id };
    }
  });

  return newState;
};

export type ArchiveCardReducerType = {
  lists: ListsType;
  archivedFromList: string | undefined;
};

export default archiveCardReducer;
