import { TaskType } from "./Board.component";

const addIndicatorReducer = (
  oldState: Pick<TaskType, "indicators">,
  indicatorID: string
): Pick<TaskType, "indicators"> => {
  const ids = oldState.indicators ? [...oldState.indicators] : [];

  if (ids.indexOf(indicatorID) === -1) {
    ids.push(indicatorID);
    return { ...oldState, indicators: ids };
  } else {
    return oldState;
  }
};

export default addIndicatorReducer;
