import React, { useState } from "react";
import { FieldInputTextBaseProps } from "./FieldInputText";
import { idGen } from "../utils";
import Field, { FieldContext, FieldContextType } from "./Field";
import "./FieldInputText.scss";

type PlainInputProps = FieldInputTextBaseProps & {
  value: string;
  forceEditMode?: boolean | undefined;
  placeholder?: string | null;
};

export const InputText_Plain = ({
  label,
  name,
  value,
  onChange,
  className: propClassName,
  maxLength,
  forceEditMode,
  placeholder,
  onKeyPress
}: PlainInputProps) => {
  const className = `field__input-text field-padding ${propClassName}`;
  const [id] = useState(idGen());
  const [hasHadInitialFocus, setInitialFocus] = useState(false);

  return (
    <Field
      id={id}
      label={label}
      readOnly={false}
      useCustomViewMode={true}
      value={value}
      forceEditMode={forceEditMode ? forceEditMode : false}
      placeholder={placeholder || undefined}
    >
      <FieldContext.Consumer>
        {({ onBlur, selectionPosition }: FieldContextType) => {
          return (
            <input
              onBlur={(e) => {
                setInitialFocus(false);
                onBlur(e);
              }}
              ref={(inputRef) => {
                if (inputRef) {
                  inputRef.focus();
                  if (!hasHadInitialFocus) {
                    setInitialFocus(true);
                    inputRef.selectionStart = selectionPosition;
                    inputRef.selectionEnd = selectionPosition;
                  }
                }
              }}
              id={id}
              value={value}
              onChange={onChange}
              type="text"
              className={className}
              name={name}
              maxLength={maxLength}
              onKeyPress={onKeyPress}
            />
          );
        }}
      </FieldContext.Consumer>
    </Field>
  );
};

export default InputText_Plain;
