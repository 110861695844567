import React from "react";
import Modal, { Styles } from "react-modal";
import AppContext from "../AppState";
import { modalStyles } from "./Modal.styles";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  contentLabel: string;
  styles?: Styles;
  disableClose?: boolean;
  closeModal: Function;
};

const ModalContainer = ({
  children,
  isOpen,
  contentLabel,
  disableClose,
  styles,
  closeModal
}: Props) => {
  return (
    <AppContext.Consumer>
      {value => {
        if (!value.modals.setModalOpen || !value.modals) return;
        const onCloseClick = () => {
          if (!disableClose) {
            closeModal();
          }
        };
        return (
          <Modal
            isOpen={isOpen}
            onRequestClose={onCloseClick}
            style={modalStyles}
            contentLabel={contentLabel}
          >
            {!disableClose && (
              <button
                onClick={onCloseClick}
                className="modal__close"
                aria-label="Close"
              >
                ✕
              </button>
            )}
            {isOpen && children}
          </Modal>
        );
      }}
    </AppContext.Consumer>
  );
};

if (process.env.NODE_ENV !== "test") {
  // Jest tests don't have a DOM so this would fail
  // and also this is unnecessary
  Modal.setAppElement("#root");
}

export default ModalContainer;
