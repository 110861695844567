import React from "react";
import Tooltip from "@reach/tooltip";
// and the styles
import "@reach/tooltip/styles.css";

import { IndicatorType } from "../Board.component";
import "./IndicatorLabel.scss";
import { tooltipStyle } from "../Board.utils";

const IndicatorLabel = ({ title, label, onClick, id }: IndicatorType) => {
  return (
    <Tooltip label={title} style={tooltipStyle}>
      <button
        onClick={(e:React.MouseEvent<HTMLElement>) => {
          if(onClick)onClick(e,id);
        }}
        className="indicator-label"
      >
        {label}
      </button>
    </Tooltip>
  );
};

export default IndicatorLabel;
